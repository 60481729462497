import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAkunSatu, getAkunDua } from "../features/akunSlice";
import Error404 from "../components/Error404";
import Layout from "./Layout";
import FormInputTransaksi from "../components/FormInputTransaksi";

const InputTransaksi = () => {

  const INPUT_FORM = {
    date: "",
    idAkunDua: "",
    jumlah: 0,
    keterangan:{
      ketInput:"", 
      wniDewasa:"", 
      wniAnakAnak:"", 
      wnaDewasa:"", 
      wnaAnakAnak:"",
      rodaEnam:"",
      rodaEmpat:"",
      rodaDua:"",
    },
    uploadFile: "",
  };

  const { akunSatu, akunDua } = useSelector((state) => state.akun);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idAkunSatu } = useParams();
  const akun = akunSatu.data.find((akun) => akun.id == idAkunSatu);
  const { isError } = useSelector((state) => state.auth);
  const [dataAkunDua, setDataAkunDua] = useState([]);
  const [dataInput, setDataInput] = useState(INPUT_FORM);



  useEffect(() => {
    dispatch(getAkunSatu());
    dispatch(getAkunDua());
  }, [dispatch]);

  useEffect(() => {
    if (akun) {
      setDataAkunDua(
        akunDua.data.filter((item) => item.keuangan_akun_satu_id == idAkunSatu)
      );
    }
  }, [akunDua, idAkunSatu, akun]);

  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);

  if (!akun) {
    return (
      <Layout>
        <Error404 />
      </Layout>
    );
  }



  return (
    <Layout>
      <FormInputTransaksi akunSatuData={akun} setDataInput={setDataInput} dataInput={dataInput} />
    </Layout>
  );
};

export default InputTransaksi;
