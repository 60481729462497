import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const ModalNotifikasi = ({
  open,
  handleClose,
  buttonAction,
  status,
  contentText,
  icon,
  title
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: "22px", padding: "10px" } }}
    >
      <DialogTitle id="alert-dialog-title" textAlign='center'>{title}</DialogTitle>

      <DialogContent>
        <Box sx={{ textAlign: "center", padding: "10px" }}>
          {icon}
        </Box>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            color: "#2B2E48",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleClose()}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#7B57E0", textTransform: "capitalize" }}
          onClick={buttonAction}
          autoFocus
        >
          Ya, Saya Yakin
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalNotifikasi;
