import React, { useEffect } from "react";
import Layout from "./Layout";
import TabelAkun from "../components/TabelAkun";
import { useDispatch, useSelector } from "react-redux";
import { getAkunSatu, getAkunDua } from "../features/akunSlice";
import { getPaket } from "../features/paketSlice";
import { Box, Typography } from "@mui/material";
import CardComponent from "../components/CardComponent";
import { useNavigate } from "react-router-dom";

const PengaturanAkun = () => {
  const { akunSatu, akunDua } = useSelector((state) => state.akun);
  // const { paket } = useSelector((state) => state.paket);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    // dispatch(getPaket());
    dispatch(getAkunSatu());
    dispatch(getAkunDua());
  }, [dispatch]);

  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);

  return (
    <Layout>
      <CardComponent
        // title={'Pengaturan'}
        subTitle={'Pengaturan'}
        content={
          <>
            <Box px={3}>
              {akunSatu.data.map((item, index) => {
                const rows = akunDua.data.filter(
                  (data) => data.keuangan_akun_satu_id === item.id
                );
                return (
                  <Box key={index} my={4}>
                    <TabelAkun akunSatu={item} rows={rows} />
                  </Box>
                )
              })}
              <Box my={4}>
                {/* <TabelAkun akunSatu={{ id: "3", nama_akun: "event" }} rows={paket.data} /> */}

              </Box>
            </Box>
          </>
        }
      />

    </Layout>
  );
};

export default PengaturanAkun;
