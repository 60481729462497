import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";
import {
  Grid,
  Paper,
  useTheme,
  Box,
  Typography,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactComponent as Logo } from "../assets/icons/logoJatiluwih.svg";

const Login = () => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [reloadDone, setReloadDone] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  const Auth = async (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const itemLogos = [
    {
      img: "/logos/logo_ipbi.png",
      title: "IPB Internasional",
    },
    {
      img: "/logos/logo_kotak_roamin.png",
      title: "Roamin",
    },
    {
      img: "/logos/logo_balichili.png",
      title: "The Balichili",
    },
  ];

  return (
    <Grid container component="main" sx={{ height: "95vh" }}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          backgroundColor: "#F1F3F4",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <Logo style={{ width: "auto", height: "100" }} />
        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: 7, width: "80%" }}
        >
          <Box>
            <Box>
              <Typography variant="h3" align="center">
                Selamat Datang
              </Typography>
              <Typography variant="subtitle1" align="center" sx={{ mb: 3 }}>
                Masukan Email dan Password Untuk melanjutkan
              </Typography>
            </Box>
            <Box component="form" noValidate onSubmit={Auth}>
              {isError && <Typography align="center">{message}</Typography>}
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <InputLabel htmlFor="email">E-mail</InputLabel>
                  <TextField
                    fullWidth
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Masukan Email"
                  />
                </Grid>
                <Grid item>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    placeholder="Masukan Password"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" />,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained" type="submit">
                    {isLoading ? "Loading..." : "Login"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography textAlign="center" fontWeight={600}>
            Support By:
          </Typography>
          <ImageList cols={3} gap={8}>
            {itemLogos.map((item, index) => (
              <ImageListItem key={index}>
                <Box
                  component="img"
                  sx={{
                    width: "150px",
                    height: "auto",
                  }}
                  alt={item.title}
                  src={item.img}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          backgroundImage: `url(images/bg_login.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            order: 2, // Mengubah urutan agar gambar berada di bawah saat layar kecil
            height: "30vh", // Sesuaikan tinggi gambar untuk layar kecil
          },
        }}
      ></Grid>
    </Grid>
  );
};

export default Login;
