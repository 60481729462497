import { Grid, Table, TableHead, TableRow, TableCell, Typography, Card, CardContent, TableBody } from "@mui/material";
import AppWidgetSummary from "./AppWidgetSummary";
import AppCurrentVisits from "./AppCurrentVisit";
import { fCurrencyIDR } from "../features/numberFormat";
import { ReactComponent as Saldo } from "../assets/icons/frame1.svg";
import { ReactComponent as Pendapatan } from "../assets/icons/frame2.svg";
import { ReactComponent as Pengeluaran } from "../assets/icons/frame3.svg";
import { ReactComponent as Event } from "../assets/icons/frame4.svg";


const AppView = ({ currentYearBalance, totalIncome, totalExpenses, eventData, eventDataYear, incomeChartData, expenseChartData, eventChartData }) => {

  const allYearsSet = new Set([
    ...incomeChartData.map(data => data.label),
    ...expenseChartData.map(data => data.label),
    ...eventChartData.map(data => data.label),
  ]);
  
  // Convert the set to an array and sort it
  const allYears = Array.from(allYearsSet).sort((a, b) => b - a);
 
    return (
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title="Saldo"
            title2="Lihat Detail"
            total={currentYearBalance}
            color="success"
            icon={<Saldo />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title="Pendapatan"
            title2="Lihat Detail"
            total={totalIncome}
            color="info"
            icon={<Pendapatan />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title="Pengeluaran"
            title2="Lihat Detail"
            total={totalExpenses}
            color="warning"
            icon={<Pengeluaran />}
          />
        </Grid>
      
        {/* <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title="Events"
            title3="Lihat Detail"
            total={eventData}
            color="error"
            icon={<Event />}
          />
        </Grid> */}

        <Grid item xs={12} sm={6} md={4}>
          <AppCurrentVisits
            title="Pendapatan"
            chart={{
              series:incomeChartData,
            }}
          />
        </Grid>
   
        <Grid item xs={12} sm={6} md={4}>
          <AppCurrentVisits
            title="Pengeluaran"
            chart={{
              series: expenseChartData,
            }}
          />
        </Grid>
        <Grid  item xs={12} sm={6} md={4}>
          {/* <AppCurrentVisits
            title="Events"
            chart={{
              series: eventChartData,
            }}
          /> */}
        </Grid>

        <Grid  item xs={12} >
        <Card elevation={3} sx={{marginBottom: 5}}>
        <CardContent>
        <Grid item xs={12} sx={{ mt:1, mb:2 }}>
        <Typography variant='h6'>Tabel Laporan</Typography>
       </Grid>
        <Table aria-label="simple table" sx={{ width: "100%" }}>
          <TableHead sx={{ background: "#F1F3F4" }}>
            <TableRow>
                  <TableCell
                    sx={{ color: "#7D7D7D", fontWeight: 600 }}
                    width={100}
                    align="left"
                  >
                    Tahun
                  </TableCell>
                  <TableCell
                    sx={{ color: "#7D7D7D", fontWeight: 600 }}
                    width={200}
                    align="left"
                  >
                    Pendapatan
                  </TableCell>
              
                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={200}
                  align="left"
                >
                  Pengeluaran
                </TableCell>
                {/* <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={200}
                  align="left"
                >
                  Event
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
            {allYears.map(year => {
                const incomeData = incomeChartData.find(data => data.label === year) || { value: 0 };
                const expenseData = expenseChartData.find(data => data.label === year) || { value: 0 };
                // const eventData = eventChartData.find(data => data.label === year) || { value: 0 };

                return (
                  <TableRow key={year}>
                    <TableCell align="left">{year}</TableCell>
                    <TableCell align="left">{fCurrencyIDR(incomeData.value || '0')}</TableCell>
                    <TableCell align="left">
                      {fCurrencyIDR(expenseData.value || '0')}
                    </TableCell>
                    {/* <TableCell align="left">
                      {fCurrencyIDR(eventData.value || '0')}
                    </TableCell> */}
                  </TableRow>
                );
              })}
             </TableBody>
          
       </Table>
       </CardContent>
       </Card>
       </Grid>
      </Grid>
    );
}
export default AppView;
