import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  paket: {
    data: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

export const getPaket = createAsyncThunk("akun/getPaket", () => {
  return axios.get("/paket").then((response) => response.data.data);
});

export const createPaket = createAsyncThunk(
  "akun/createPaket",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/paket", {
        nama_paket: data.namaPaket,
      });
      return response.data.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const updatePaket = createAsyncThunk(
  "akun/updatePaket",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(`/paket/${data.id}`, {
        nama_paket: data.namaPaket,
      });
      return response.data.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const deletePaket = createAsyncThunk(
  "akun/deletePaket",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`/paket/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const paketSlice = createSlice({
  name: "paket",
  initialState,
  // reducers: {
  //   reset: (state) => initialState,
  // },
  extraReducers: (builder) => {
    builder.addCase(getPaket.pending, (state) => {
      state.paket.isLoading = true;
    });
    builder.addCase(getPaket.fulfilled, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isSuccess = true;
      state.paket.data = action.payload;
    });
    builder.addCase(getPaket.rejected, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isError = true;
      state.paket.message = action.payload;
    });

    //create Akun Dua
    builder.addCase(createPaket.pending, (state) => {
      state.paket.isLoading = true;
    });
    builder.addCase(createPaket.fulfilled, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isSuccess = true;
      state.paket.data.push(action.payload);
    });
    builder.addCase(createPaket.rejected, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isError = true;
      state.paket.message = action.payload;
    });

    //update Akun Dua
    builder.addCase(updatePaket.pending, (state) => {
      state.paket.isLoading = true;
    });
    builder.addCase(updatePaket.fulfilled, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isSuccess = true;
      const updateItem = action.payload;
      const index = state.paket.data.findIndex(
        (item) => item.id === updateItem.id
      );
      if (index !== -1) {
        state.paket.data[index] = updateItem;
      }
    });
    builder.addCase(updatePaket.rejected, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isError = true;
      state.paket.message = action.payload;
    });

    //delete Akun Dua
    builder.addCase(deletePaket.pending, (state) => {
      state.paket.isLoading = true;
    });
    builder.addCase(deletePaket.fulfilled, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isSuccess = true;
      const deleteItem = action.payload;
      const index = state.paket.data.findIndex(
        (item) => item.id === deleteItem.id
      );
      state.paket.data.splice(index, 1);
    });
    builder.addCase(deletePaket.rejected, (state, action) => {
      state.paket.isLoading = false;
      state.paket.isError = true;
      state.paket.message = action.payload;
    });
  },
});

// export const { reset } = akunSlice.actions;
export default paketSlice.reducer;
