import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import { ThemeProvider, createTheme } from "@mui/material";
import PengaturanAkun from "./pages/PengaturanAkun";
import RekapLaporanBulanan from "./pages/RekapLaporanBulanan";
import InputTransaksi from "./pages/InputTransaksi";
import InputEvent from "./pages/InputEvent";
import RekapLaporanTahunan from "./pages/RekapLaporanTahunan";
import RekapLaporanEvent from "./pages/RekapLaporanEvent";
import { getMe } from "./features/authSlice";


const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
  },
  palette: {
    primary: {
      main: "#7B57E0",
    },
  },
});


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);


  useEffect(() => {
    if (!isLoading && isError && !user) {
      navigate("/login");
    }
  }, [user, isLoading, isError, navigate]);


  return (
    <div>
      <ThemeProvider theme={theme}>
      <Routes>
      <Route path="/login" element={user ? <Navigate to="/" replace /> : <Login />} />
          {user ? (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/add" element={<AddUser />} />
              <Route path="/users/edit/:id" element={<EditUser />} />
              <Route path="/pengaturan-akun" element={<PengaturanAkun />} />
              <Route path="/laporan-bulanan" element={<RekapLaporanBulanan />} />
              <Route path="/laporan-tahunan" element={<RekapLaporanTahunan />} />
              <Route path="/input/:idAkunSatu" element={<InputTransaksi />} />
              <Route path="/input/event" element={<InputEvent />} />
              <Route path="/laporan-event" element={<RekapLaporanEvent />} />
            </>
          ) : null}
          </Routes>
      </ThemeProvider>
    </div>
  );
}


export default App;
