import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";
import CardComponent from "../components/CardComponent";
import LaporanEvent from "../components/LaporanEvent";
import { getAllEvent } from "../features/eventSlice";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDFRekapEvent from "../components/PDF/PDFRekapEvent";

const RekapLaporanEvent = () => {

    const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];

    const { event } = useSelector((state) => state.event);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState('')
    const [dataPerDate, setDataPerDate] = useState([])
    const [fullDate, setFullDate] = useState([])
    const { isError } = useSelector((state) => state.auth);

    const handlePerDate = (value, key) => {
        setDataPerDate(value)
        setSelectedDate(key)
    }

    useEffect(() => {
        dispatch(getAllEvent());
    }, [dispatch]);


    useEffect(() => {
        const dateGroup = event.data.reduce((r, o) => {
            let date = new Date(o.tanggal);
            let monthYearKey = `${date.getMonth() + 1}-${date.getFullYear()}`;

            (r[monthYearKey]) ? r[monthYearKey].push(o) : r[monthYearKey] = [o];

            return r;
        }, {});

        let array = Object.entries(dateGroup)

        array.sort((a, b) => {
            let dateA = new Date(a[0].split('-')[1], a[0].split('-')[0]);
            let dateB = new Date(b[0].split('-')[1], b[0].split('-')[0]);
            return dateA - dateB;
        });

        let sortArray = Object.fromEntries(array)

        // Dapatkan kunci terakhir dari objek
        let lastKey = Object.keys(sortArray)[Object.keys(sortArray).length - 1];

        // Dapatkan nilai terakhir dari objek
        let lastValue = sortArray[lastKey];

        setFullDate(sortArray);
        setDataPerDate(lastValue)
        setSelectedDate(lastKey)

    }, [event.data])

    // useEffect(() => {
    //     if (isError) {
    //         navigate("/");
    //     }
    // }, [isError, navigate]);





    return (
        <Layout>
            <CardComponent
                title={'Rekap Pendapatan Event'}
                subTitle={'PILIH HASIL PENDAPATAN BERDASARKAN TAHUN DAN BULAN'}
                content={
                    <>
                        <Box px={3} my={3}>
                            <Typography variant="h5">Pilih :</Typography>
                            <Box my={1}>
                                <Grid container direction='row' spacing={1}  >
                                    {Object.entries(fullDate)?.reverse().map(([key, value]) => {
                                        let dateSplit = key.split('-')
                                        return (
                                            <Grid key={key} item >
                                                <Button
                                                    variant={selectedDate === key ? "contained" : "outlined"}
                                                    sx={{
                                                        color: selectedDate === key ? "#FFFFFF" : "primary",
                                                        borderColor: "primary",
                                                        textTransform: 'none',

                                                        display: 'block', width: '100px', height: 'auto', wordBreak: 'keep-all',
                                                        ':hover': {
                                                            backgroundColor: "primary.main",
                                                            borderColor: "primary.main",
                                                            color: "#FFFFFF"
                                                        },
                                                    }}
                                                    onClick={() => handlePerDate(value, key)}
                                                    fullWidth
                                                >
                                                    {`${monthNames[dateSplit[0] - 1]} ${dateSplit[1]}`}
                                                </Button>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <PDFDownloadLink document={<PDFRekapEvent data={dataPerDate?.sort((a, b) => new Date(a.tanggal) - new Date(b.tanggal))} monthNames={monthNames} />} filename="FORM">
                                    {({ loading }) => (loading ? <button>Loading Document...</button> : <Button
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            minWidth: 'auto'
                                        }}
                                    >
                                        Unduh</Button>)}
                                </PDFDownloadLink>
                            </Box>
                        </Box>
                        {/* Tolong Perbaiki inputan pengeluaran dan pendapatan */}

                        {/* <Box>
                            <PDFViewer width={'100%'} height={500}>
                                <PDFRekapEvent data={dataPerDate?.sort((a, b) => new Date(a.tanggal) - new Date(b.tanggal))} monthNames={monthNames} />
                            </PDFViewer>
                        </Box> */}
                        <Box>
                            {
                                dataPerDate?.sort((a, b) => new Date(a.tanggal) - new Date(b.tanggal)).map((item, index) =>
                                    <LaporanEvent key={index} data={item} monthNames={monthNames} />
                                )
                            }
                        </Box>



                    </>
                }
            />
        </Layout>
    );
};

export default RekapLaporanEvent;
