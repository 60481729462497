import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

const InputFile = ({ id, error, helperText, fileName, onChange, placeholder }) => {
  return (
    <>
      <InputLabel htmlFor={id}>
        <Box
          sx={{
            border: 1,
            padding: "5px",
            borderRadius: 1,
            borderColor: error ? "error.main" : "rgba(0, 0, 0, 0.23)",
            "&:hover": {
              borderColor: error ? "error.main" : "rgba(0, 0, 0, 1)",
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                whiteSpace: "nowrap",
                textAlign: "start",
                pl: 2
              }}
            >
              <Typography>
                {fileName ? fileName : placeholder}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} display="flex" justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                component="span"
                color="primary"
              >
                Pilih File
              </Button>
            </Grid>
          </Grid>
        </Box>
        <FormHelperText sx={{ color: "error.main" }}>
          {helperText}
        </FormHelperText>
      </InputLabel>

      <TextField
        sx={{ display: "none" }}
        size="small"
        fullWidth
        placeholder="Upload Bukti"
        id={id}
        type="file"
        // value={dataInput.uploadFile}
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
        inputProps={{ accept: "application/pdf" }}
      />
    </>
  );
};

export default InputFile;
