import { Document, Page, Text, StyleSheet, View, Font } from '@react-pdf/renderer'
import React, { useEffect, useState } from "react";
import { fCurrencyIDR, fCurrencyNoRp } from "../../features/numberFormat";


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: "Times-Roman"
    },
    subTitle: {
        fontSize: 12,
        wordWrap: 'break-word',
        fontWeight: 600,
        fontFamily: 'Times-Bold',
    },
    header: {
        fontSize: 14,
        marginBottom: 20,
        textAlign: "center",
        fontWeight: 800,
        wordWrap: "break-word",
        fontFamily: 'Times-Bold'
    },
    flexContainer: {
        width: '100%',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        marginVertical: 2,
    },
    flexItem: {
        width: '100%',
        fontSize: 12,
        wordWrap: "break-word",
        fontFamily: "Times-Roman"

    },
    PendapataContainer: {
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 5,
        display: "inline-flex",
    },
    bullet: {
        height: '100%',
    },
    divider: { borderTop: '1px #2B2E48 solid', marginLeft: 'auto', marginRight: 0 }
});

const PDFRekapTahun = ({ data, totalPendapatan, totalPengeluaran, totalSaldo, formattedLabel }) => {


    return (
        <Document size="A4">

            <Page style={styles.body}>

                <View style={styles.header} >
                    <Text>Laporan Keuangan Bulanan Desa Wisata Jatiluwih</Text>
                    <Text>{formattedLabel}</Text>
                </View>

                <View>
                    {/* Pendapatan */}
                    <View style={styles.PendapataContainer}>
                        <Text style={styles.subTitle}>Pendapatan</Text>
                        {/* Harga */}
                        {data
                            .filter((transaksi) => transaksi.jumlahPendapatan > 0) // Filter hanya yang memiliki pendapatan
                            .map((transaksi, index) => (
                                <View key={index} style={styles.flexContainer}>
                                    <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                        <View style={styles.bullet}>
                                            <Text>{'\u2022' + " "}</Text>
                                        </View>
                                        <Text>{transaksi.nama_akun}</Text>
                                    </View>
                                    <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                        <View style={{ width: '60%' }}>
                                            <Text>Rp </Text>
                                        </View>
                                        <View style={{ width: '40%' }}>
                                            <Text>{fCurrencyNoRp(transaksi.jumlahPendapatan?.toString().replace('.', ','))}</Text>
                                        </View>
                                    </View>
                                </View>
                            ))}


                        <View style={[styles.divider, { width: '30%' }]}></View>
                        {/* Total Pemasukan */}
                        <View style={styles.flexContainer}>
                            <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                <Text style={{
                                    fontSize: 12,
                                    fontFamily: 'Times-Bold',

                                    wordWrap: 'break-word'
                                }} >Total Pemasukan</Text>
                            </View>
                            <View style={[styles.flexItem, {
                                display: 'flex', flexDirection: 'row', textAlign: 'right', fontSize: 12,

                                wordWrap: 'break-word',
                                fontFamily: 'Times-Bold'
                            }]}>
                                <View style={{ width: '60%' }}>
                                    <Text>Rp </Text>
                                </View>
                                <View style={{ width: '40%' }}>
                                    <Text>{totalPendapatan !== 0 ? fCurrencyNoRp(totalPendapatan?.toString().replace('.', ',')) : '-'}</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    {/* Pengeluaran */}
                    <View style={styles.PendapataContainer}>
                        <Text style={styles.subTitle}>Pengeluaran</Text>
                        {/* Akomodasi */}
                        {data
                            .filter((transaksi) => transaksi.jumlahPengeluaran > 0) // Filter hanya yang memiliki pendapatan
                            .map((transaksi, index) => (
                                <View key={index} style={styles.flexContainer}>
                                    <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                        <View style={styles.bullet}>
                                            <Text>{'\u2022' + " "}</Text>
                                        </View>
                                        <Text>{transaksi.nama_akun}</Text>
                                    </View>
                                    <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                        <View style={{ width: '60%' }}>
                                            <Text>Rp </Text>
                                        </View>
                                        <View style={{ width: '40%' }}>
                                            <Text>{fCurrencyNoRp(transaksi.jumlahPengeluaran?.toString().replace('.', ','))}</Text>
                                        </View>
                                    </View>
                                </View>
                            ))}

                        <View style={[styles.divider, { width: '30%' }]}></View>
                        {/* Total Pengeluaran */}
                        <View style={styles.flexContainer}>
                            <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                <Text style={{
                                    fontSize: 12,
                                    fontFamily: 'Times-Bold',

                                    wordWrap: 'break-word'
                                }} >Total Pengeluaran</Text>
                            </View>
                            <View style={[styles.flexItem, {
                                display: 'flex', flexDirection: 'row', textAlign: 'right', fontSize: 12,

                                wordWrap: 'break-word',
                                fontFamily: 'Times-Bold'
                            }]}>
                                <View style={{ width: '60%' }}>
                                    <Text>Rp </Text>
                                </View>
                                <View style={{ width: '40%' }}>
                                    <Text>{fCurrencyNoRp(totalPengeluaran?.toString().replace('.', ','))}</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.divider, { width: '35%' }]}></View>
                    <View style={styles.flexContainer}>
                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                            <Text style={{
                                fontSize: 12,
                                wordWrap: 'break-word',
                                fontFamily: 'Times-Bold'
                            }} >Total Saldo</Text>
                        </View>
                        <View style={[styles.flexItem, {
                            display: 'flex', flexDirection: 'row', textAlign: 'right', fontSize: 12,
                            wordWrap: 'break-word',
                            fontFamily: 'Times-Bold'
                        }]}>
                            <View style={{ width: '60%' }}>
                                <Text>Rp </Text>
                            </View>
                            <View style={{ width: '40%' }}>
                                <Text>{fCurrencyNoRp(totalSaldo?.toString().replace('.', ','))}</Text>
                            </View>
                        </View>

                    </View>
                </View>
            </Page>

        </Document>
    );
}

export default PDFRekapTahun;