import { Typography, Card, CardContent, Box, Button, Grid, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { fCurrencyNoRp } from "../features/numberFormat";
import GridTotal from "./InvoicePrice/GridTotal";
import GridPrice from "./InvoicePrice/GridPrice";
import SaveIcon from '@mui/icons-material/Save';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDFRekapTahun from "./PDF/PDFRekapTahun";
import environment from "../enviroment";


const RekapTahunan = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [availableYears, setAvailableYears] = useState([]);
  const [defaultYear, setDefaultYear] = useState();
  const [akumulasiData, setAkumulasiData] = useState([]);
  const [totalPendapatan, setTotalPendapatan] = useState(0);
  const [totalPengeluaran, setTotalPengeluaran] = useState(0);
  const [totalSaldo, setTotalSaldo] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [transactionsResponse, akunDuaResponse] = await Promise.all([
          axios.get("/transaksi"),
          axios.get("/akun-dua"),
        ]);

        const transactionsData = transactionsResponse.data.data;
        const akunDuaData = akunDuaResponse.data;

        const currentYear = new Date().getFullYear();

        if (!selectedYear) {
          setDefaultYear(currentYear);
          setSelectedYear(currentYear);
        }

        transactionsData.sort((a, b) => new Date(a.date) - new Date(b.date));

        const uniqueYears = [...new Set(transactionsData.map(transaction => new Date(transaction.date).getFullYear()))];
        const sortedUniqueYears = uniqueYears.sort((a, b) => b - a); // Sorting secara descending
        setAvailableYears(sortedUniqueYears);


        let saldo = 0;

        const filteredData = transactionsData
          .filter((transaction) => {
            const transactionDate = new Date(transaction.date);
            return (
              (!selectedYear || transactionDate.getFullYear() === selectedYear)
            );
          })
          .map((transaction) => {
            const correspondingAkunDua = akunDuaData.find((akun) => akun.id === transaction.keuangan_akun_dua_id);
            const correspondingAkunSatuId = correspondingAkunDua ? correspondingAkunDua.keuangan_akun_satu_id : null;

            let transaksi = {};

            if (correspondingAkunSatuId === 1) {
              saldo += transaction.jumlah || 0;
              transaksi = {
                ...transaction,
                nama_akun: correspondingAkunDua ? correspondingAkunDua.nama_akun : null,
                kode: correspondingAkunDua ? correspondingAkunDua.kode : null,
                jumlahPendapatan: transaction.jumlah || 0,
                jumlahPengeluaran: null,
                saldo: saldo,
                correspondingAkunSatuId: correspondingAkunSatuId,
              };
            } else if (correspondingAkunSatuId === 2) {
              saldo -= transaction.jumlah || 0;
              transaksi = {
                ...transaction,
                nama_akun: correspondingAkunDua ? correspondingAkunDua.nama_akun : null,
                kode: correspondingAkunDua ? correspondingAkunDua.kode : null,
                jumlahPendapatan: null,
                jumlahPengeluaran: transaction.jumlah || 0,
                saldo: saldo,
                correspondingAkunSatuId: correspondingAkunSatuId,
              };
            }
            const pdfUrl = `${environment.baseUrl}/pdfs/${encodeURIComponent(transaction.uploadData)}`;

            return {
              ...transaksi,
              pdfUrl,
            };
          });

        const akumulasiData = filteredData.reduce((acc, transaction) => {
          const correspondingAkunDua = akunDuaData.find((akun) => akun.id === transaction.keuangan_akun_dua_id);
          const namaAkun = correspondingAkunDua ? correspondingAkunDua.nama_akun : null;

          if (!namaAkun) return acc;

          // Cek apakah nama_akun sudah ada di dalam akumulasiData
          const existingAkun = acc.find((item) => item.nama_akun === namaAkun);

          if (existingAkun) {
            // Jika sudah ada, tambahkan nilai pendapatan dan pengeluaran
            existingAkun.jumlahPendapatan += transaction.correspondingAkunSatuId === 1 ? transaction.jumlah : 0;
            existingAkun.jumlahPengeluaran += transaction.correspondingAkunSatuId === 2 ? transaction.jumlah : 0;
          } else {
            // Jika belum ada, tambahkan ke akumulasiData
            acc.push({
              nama_akun: namaAkun,
              jumlahPendapatan: transaction.correspondingAkunSatuId === 1 ? transaction.jumlah : 0,
              jumlahPengeluaran: transaction.correspondingAkunSatuId === 2 ? transaction.jumlah : 0,
            });
          }

          return acc;
        }, []);

        setAkumulasiData(akumulasiData);

        const totalPendapatan = akumulasiData.reduce((total, transaksi) => {
          return total + transaksi.jumlahPendapatan;
        }, 0);

        const totalPengeluaran = akumulasiData.reduce((total, transaksi) => {
          return total + transaksi.jumlahPengeluaran;
        }, 0);

        const totalSaldo = totalPendapatan - totalPengeluaran;

        setTotalPendapatan(totalPendapatan);
        setTotalPengeluaran(totalPengeluaran);
        setTotalSaldo(totalSaldo);


      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response.data);
        } else {
          console.error('Error:', error.message);
        }
      }
    };


    fetchData(selectedYear || defaultYear);
  }, [defaultYear, selectedYear]);


  const handleYearClick = (year) => {
    setSelectedYear(year);
    setDefaultYear(year);

  };


  const formattedLabel = selectedYear
    ? `Laporan Tahun ${selectedYear}`
    : 'Rekap Tahunan';

  return (
    <>
      <Box px={3} my={3}>
        <Typography variant="h6">Pilih Tahun :</Typography>
        <Box my={1}>
          <Grid container direction='row' spacing={1} >
            {availableYears.map((year) => (
              <Grid item key={year} xs={6} sm={4} md={3} lg={2}>
                <Button
                  variant={selectedYear === year ? "contained" : "outlined"}
                  sx={{
                    color: selectedYear === year ? "#FFFFFF" : "#7B57E0",
                    backgroundColor: selectedYear === year ? "#7B57E0" : "transparent",
                    borderColor: "#7B57E0",
                    ':hover': {
                      backgroundColor: "#7B57E0",
                      borderColor: "#7B57E0",
                      color: "#FFFFFF"

                    },
                  }}
                  onClick={() => handleYearClick(year)}
                  fullWidth
                >
                  {year}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 4, mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Typography variant='h6' id="pdf-typography">{formattedLabel}</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <PDFDownloadLink document={<PDFRekapTahun data={akumulasiData} totalPendapatan={totalPendapatan} totalPengeluaran={totalPengeluaran} totalSaldo={totalSaldo} formattedLabel={formattedLabel} />} filename="FORM">
                {({ loading }) => (loading ? <button>Loading Document...</button> : <Button
                  startIcon={<SaveIcon />}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    fontSize: '14px',
                    minWidth: 'auto'
                  }}
                >
                  Unduh</Button>)}
              </PDFDownloadLink>
            </Grid>
          </Grid>

          {/* <Box>
            <PDFViewer width={'100%'} height={500}>
              <PDFRekapTahun data={akumulasiData} totalPendapatan={totalPendapatan} totalPengeluaran={totalPengeluaran} totalSaldo={totalSaldo} formattedLabel={formattedLabel} />
            </PDFViewer>
          </Box> */}
          {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
            startIcon={<SaveIcon />} 
            variant="contained" 
            sx={{
              textTransform: 'none',
              fontSize: '14px', 
              minWidth: 'auto'
            }}
            onClick={downloadAsPDF}>
              Unduh</Button>
          </Grid> */}
        </Box>
      </Box>

      <Card sx={{ marginTop: 3, background: 'rgba(232, 232, 232, 0.50)' }} elevation={0}>
        <CardContent>
          <Box p={3} id="pdf-table-container">
            <Grid container spacing={1} direction="column" >
              <Grid item>
                <Typography variant="h6" fontWeight={600}>Pendapatan</Typography>

                {akumulasiData
                  .filter((transaksi) => transaksi.jumlahPendapatan > 0) // Filter hanya yang memiliki pendapatan
                  .map((transaksi, index) => (
                    <GridPrice key={index} name={transaksi.nama_akun} value={fCurrencyNoRp(transaksi.jumlahPendapatan?.toString().replace('.', ','))} />
                  ))}

                <Grid container direction="row" mt={1}>
                  <Grid item md={8} >

                  </Grid>
                  <Grid item md={4} >
                    <Divider sx={{ borderWidth: 2, borderColor: 'black' }} />
                  </Grid>
                </Grid>

                <GridTotal name={'Total Pemasukan'} value={totalPendapatan !== 0 ? fCurrencyNoRp(totalPendapatan?.toString().replace('.', ',')) : '-'} />

              </Grid>

              <Grid item >
                <Typography variant="h6" fontWeight={600}>Pengeluaran</Typography>

                {akumulasiData
                  .filter((transaksi) => transaksi.jumlahPengeluaran > 0) // Filter hanya yang memiliki pengeluaran
                  .map((transaksi, index) => (
                    <GridPrice key={index} name={transaksi.nama_akun} value={fCurrencyNoRp(transaksi.jumlahPengeluaran?.toString().replace('.', ','))} />
                  ))}

                <Grid container direction="row" mt={1}>
                  <Grid item md={8} >

                  </Grid>
                  <Grid item md={4} >
                    <Divider sx={{ borderWidth: 2, borderColor: 'black' }} />
                  </Grid>
                </Grid>

                <GridTotal name={'Total Pengeluaran'} value={totalPengeluaran !== 0 ? fCurrencyNoRp(totalPengeluaran?.toString().replace('.', ',')) : '-'} />

              </Grid>

            </Grid>
            <Grid container direction="row" mt={1}>
              <Grid item md={7} >

              </Grid>
              <Grid item md={5} >
                <Divider sx={{ borderWidth: 2, borderColor: 'black' }} />
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="space-between" mt={2}>
              <Grid item md={6}>
                <Typography variant="h6">{'Total Saldo'}</Typography>
              </Grid>
              <Grid item md={6}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item md={6} display="flex" justifyContent="flex-end">
                    <Typography variant="h5" fontWeight={600}>Rp</Typography>
                  </Grid>
                  <Grid item md={6} display="flex" justifyContent="flex-end">
                    <Typography variant="h5" fontWeight={600}>{totalSaldo !== 0 ? fCurrencyNoRp(totalSaldo?.toString().replace('.', ',')) : '-'}</Typography>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>

  )

}

export default RekapTahunan;