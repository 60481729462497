import { InputAdornment, TextField, Typography } from "@mui/material";
import { fCurrencyNoRp } from "../../features/numberFormat";
import { useEffect, useState } from "react";

function formatRupiah(angka, prefix) {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
  return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

const InputCurrency = ({
  error,
  helperText,
  value,
  onChange,
  placeholder,
  id,
  status
}) => {
  const [currency, setCurrency] = useState("");
  const [statusJumlah, setStatusJumlah] = useState(status)

  const onchangeHandler = (e) => {
    let values = e.target.value.split(",");

    if (values.length === 1 || (values.length === 2 && values[1].length <= 2)) {
      setCurrency(formatRupiah(e.target.value));
    }
  };

  const onBlurHandler = (e) => {
    let values = e.target.value.split(",");
    if (values.length === 1 && values[0] !== "") {
      setCurrency((prev) => prev.concat(",00"));
    }

    if (values !== "" && values[1]?.length === 1) {
      setCurrency((prev) => prev.concat("0"));
    }

    let num = currency.split(".").join("");
    let numFloat = num.split(",").join(".");
    onChange(numFloat === "" ? 0 : parseFloat(numFloat).toFixed(2));
  };

  const onClickHandler = () => {
    setStatusJumlah("add");
  };

  useEffect(() => {
    if (value === 0) {
      setCurrency("");
    }
    if(statusJumlah === 'edit') {
      setCurrency(fCurrencyNoRp(value))
    }
  }, [value, status]);

  return (
    <>
      <TextField
        error={error}
        helperText={helperText}
        size="small"
        fullWidth
        placeholder={placeholder}
        id={id}
        type="text"
        value={currency}
        onChange={onchangeHandler}
        onBlur={onBlurHandler}
        onClick={onClickHandler}
        InputProps={{
          startAdornment: <InputAdornment position="start">Rp </InputAdornment>,
        }}
      />
    </>
  );
};

InputCurrency.defaultProps = {
  status: "add", // or any default value you want
};

export default InputCurrency;
