import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  DialogContentText,
} from "@mui/material";
import ModalInput from "./ModalInput";
import ModalNotifikasi from "./ModalNotifikasi";
import { useDispatch } from "react-redux";
import {
  createAkunDua,
  updateAkunDua,
  deleteAkunDua,
} from "../features/akunSlice";
import { createPaket, deletePaket, updatePaket } from "../features/paketSlice";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/delete.svg";
import AlertBar from "./AlertBar";
import { ReactComponent as WarningIcon } from "../assets/icons/warning.svg";

export default function TabelAkun({ akunSatu, rows }) {
  // const classes = useStyles();
  const DATA_PENGATURAN = {
    id: "",
    kode: "",
    namaAkun: "",
    namaPaket: "",
    idAkunSatu: "",
    status: "",
  };


  const EMPTY_FORM_VALIDATION = {
    namaAkun: { error: false, helperText: "" },
    kode: { error: false, helperText: "" },
    namaPaket: { error: false, helperText: "" },
  };

  const dispatch = useDispatch();
  const [dataPengaturan, setDataPengaturan] = useState(DATA_PENGATURAN);
  const [open, setOpen] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [formValidation, setFormValidation] = useState(EMPTY_FORM_VALIDATION);

  const validateFormAkun = () => {
    var formValidation = EMPTY_FORM_VALIDATION;
    var isError = false;

    if (akunSatu.nama_akun === 'event') {
      if (dataPengaturan.namaPaket == "") {
        formValidation.namaPaket.error = true;
        formValidation.namaPaket.helperText = "Mohon masukkan Nama Paket";
        isError = true;
      }
    } else {
      if (dataPengaturan.namaAkun == "") {
        formValidation.namaAkun.error = true;
        formValidation.namaAkun.helperText = "Mohon masukkan Nama Akun";
        isError = true;
      }
      if (dataPengaturan.kode == "") {
        formValidation.kode.error = true;
        formValidation.kode.helperText = "Mohon masukkan Kode Akun";
        isError = true;
      }
    }
    setFormValidation(formValidation);
    return !isError;
  };

  const addAkun = (e) => {
    const isValid = validateFormAkun();
    if (isValid) {
      e.preventDefault();
      if (akunSatu.nama_akun === "event") {
        dispatch(createPaket(dataPengaturan));
      } else {
        dispatch(createAkunDua(dataPengaturan));
      }
      setDataPengaturan(DATA_PENGATURAN);
      setOpen(false);
      setOpenAlert((prev) => !prev);
      setMessage(`${akunSatu.nama_akun} Berhasil Disimpan`);
      setSeverity("success");
    } else {
      setOpenAlert((prev) => !prev);
      setMessage(`Mohon Periksa Kembali Data Anda`);
      setSeverity("error");
    }

  };

  const editAkun = () => {
    if (akunSatu.nama_akun === "event") {
      dispatch(updatePaket(dataPengaturan));
    } else {
      dispatch(updateAkunDua(dataPengaturan));
    }
    setOpen(false);
    setDataPengaturan(DATA_PENGATURAN);
    setOpenAlert((prev) => !prev);
    setMessage(`${akunSatu.nama_akun} Berhasil Diedit`);
    setSeverity("success");
  };

  const deleteAkun = () => {
    if (akunSatu.nama_akun === "event") {
      dispatch(deletePaket(dataPengaturan.id));
    } else {
      dispatch(deleteAkunDua(dataPengaturan.id));
    }
    setDataPengaturan(DATA_PENGATURAN);
    setOpenNotif(false);
    setOpenAlert((prev) => !prev);
    setMessage(`${akunSatu.nama_akun} Berhasil Dihapus`);
    setSeverity("success");
  };

  const handleOpen = () => {
    if (akunSatu.nama_akun === "event") {
      setDataPengaturan({ ...dataPengaturan, status: "add" });
    } else {
      setDataPengaturan({
        ...dataPengaturan,
        idAkunSatu: akunSatu.id,
        status: "add",
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDataPengaturan(DATA_PENGATURAN);
    setFormValidation(EMPTY_FORM_VALIDATION)
  };

  const handleOpenEdit = (data) => {
    setOpen(true);
    setDataPengaturan({ ...data });
  };

  const handleOpenNotif = (data) => {
    setOpenNotif(true);
    setDataPengaturan({ ...data });
  };

  const handleCloseNotif = () => {
    setOpenNotif(false);
    setDataPengaturan(DATA_PENGATURAN);
  };

  return (
    <>
      <AlertBar
        open={openAlert}
        setOpen={setOpenAlert}
        message={message}
        severity={severity}
      />
      <Box marginBottom={3}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          paddingY={1}
        >
          <Grid item>
            <Typography variant="h4" textTransform="capitalize">
              Pengaturan {akunSatu.nama_akun}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: "#7B57E0",
                "&:hover": { backgroundColor: "#8c70e0" },
              }}
              variant="contained"
              onClick={handleOpen}
            >
              Tambah {akunSatu.nama_akun}
            </Button>
          </Grid>
        </Grid>

        <ModalInput
          open={open}
          handleClose={handleClose}
          editAkun={editAkun}
          addAkun={addAkun}
          dataAkunDua={dataPengaturan}
          setDataAkunDua={setDataPengaturan}
          akunSatu={akunSatu}
          formValidation={formValidation}
        />

        <ModalNotifikasi
          open={openNotif}
          handleClose={handleCloseNotif}
          buttonAction={deleteAkun}
          status={dataPengaturan.status}
          title={'Konfirmasi Hapus Data'}
          icon={<WarningIcon />}
          contentText={
            <>
              <DialogContentText>
                apakah anda yakin ingin menghapus data?
              </DialogContentText>
              <DialogContentText
                sx={{
                  marginY: 2,
                }}
              >
                dengan menghapus berarti anda meyetujui konsekuensi bahwa data
                yang telah dihapus{" "}
                <span style={{ fontWeight: 800 }}>tidak dapat dikembalikan.</span>
              </DialogContentText>
            </>
          }
        />
        <TableContainer component={Paper} variant="outlined">
          <Table aria-label="simple table" sx={{ width: "100%" }}>
            <TableHead sx={{ background: "#F1F3F4" }}>
              <TableRow>
                {akunSatu.nama_akun !== "event" ? (
                  <>
                    <TableCell
                      sx={{ color: "#7D7D7D", fontWeight: 600 }}
                      width={"20%"}
                      align="left"
                    >
                      Kode
                    </TableCell>
                    <TableCell
                      sx={{ color: "#7D7D7D", fontWeight: 600 }}
                      width={"60%"}
                      align="left"
                    >
                      Nama
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{ color: "#7D7D7D", fontWeight: 600 }}
                    width={"80%"}
                    align="left"
                  >
                    Nama
                  </TableCell>
                )}

                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={"20%"}
                  align="center"
                >
                  Aksi
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {akunSatu.nama_akun !== "event" ? (
                    <>
                      <TableCell component="th" scope="row">
                        {row.kode}
                      </TableCell>
                      <TableCell align="left">{row.nama_akun}</TableCell>
                    </>
                  ) : (
                    <TableCell align="left">{row.nama_paket}</TableCell>
                  )}

                  <TableCell align="left">
                    <IconButton
                      aria-label="edit"
                      sx={{ width: "2.5rem", height: "2.5rem" }}
                      onClick={() =>
                        akunSatu.nama_akun === "event"
                          ? handleOpenEdit({
                            ...dataPengaturan,
                            id: row.id,
                            namaPaket: row.nama_paket,
                            status: "update",
                          })
                          : handleOpenEdit({
                            ...dataPengaturan,
                            id: row.id,
                            kode: row.kode,
                            namaAkun: row.nama_akun,
                            idAkunSatu: akunSatu.id,
                            status: "update",
                          })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      sx={{ width: "2.5rem", height: "2.5rem" }}
                      onClick={() =>
                        handleOpenNotif({ ...dataPengaturan, id: row.id, status: "delete" })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
