export function fKeterangan (keteranganString) {
    try {
      const keteranganObj = JSON.parse(keteranganString);
  
      const formattedPairs = Object.entries(keteranganObj)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${getLabelByKeyName(key)} : ${value}`);

      const formattedKeterangan = formattedPairs.join('\n');

    return formattedKeterangan;

    } catch (error) {
      console.error('Error parsing keterangan:', error);
      return '-';
    }
  };


  const getLabelByKeyName = (key) => {
    switch (key) {
      case 'ketInput':
        return 'Keterangan ';
      case 'wniDewasa':
        return 'WNI Dewasa ';
      case 'wniAnakAnak':
        return 'WNI Anak-anak ';
      case 'wnaDewasa':
        return 'WNA Dewasa ';
      case 'wnaAnakAnak':
        return 'WNA Anak-anak ';
      case 'rodaEnam':
        return 'Micro Bus & Bus ';
      case 'rodaEmpat':
        return 'Mobil ';
      case 'rodaDua':
        return 'Motor ';
      default:
        return '';
    }
  };
  