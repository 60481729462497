import { Typography, Card, CardContent, Box, Grid } from "@mui/material";

const CardComponent = ({ title, subTitle, content, action }) => {
  return (
    <Card>
      <CardContent sx={{ padding: 4 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>{""}</Grid>
        </Grid>

        <Card elevation={2}>
          <CardContent sx={{ padding: 0 }}>
            <Box bgcolor="#7B57E0">
              <Typography
                m={0}
                px={3}
                py={2}
                sx={{ textTransform: "uppercase", color: "white" }}
                variant="subtitle1"
              >
                {subTitle}
              </Typography>
            </Box>
            {content}
          </CardContent>
        </Card>
        <Box marginTop={3}>{action}</Box>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
