import { Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer'
import React, { useState } from "react";
import { fCurrencyNoRp } from "../../features/numberFormat";


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: "Times-Roman"
    },
    subTitle: {
        fontSize: 12,
        wordWrap: 'break-word',
        fontFamily: 'Times-Bold',
    },
    header: {
        fontSize: 14,
        marginBottom: 20,
        textAlign: "center",
        wordWrap: "break-word",
        fontFamily: 'Times-Bold'
    },
    flexContainer: {
        width: '100%',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        marginVertical: 2,
    },
    flexItem: {
        width: '100%',
        fontSize: 12,
        letterSpacing: 0.24,
        wordWrap: "break-word",
        fontFamily: "Times-Roman"

    },
    PendapataContainer: {
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 5,
        display: "inline-flex",
    },
    bullet: {
        height: '100%',
    },
    textBold: {
        wordWrap: 'break-word',
        fontFamily: 'Times-Bold'
    },
    divider: { borderTop: '1px #2B2E48 solid', marginLeft: 'auto', marginRight: 0 }
});

const PDFRekapEvent = ({ data, monthNames }) => {

    const calculateDiscountedPrice = (price, discount) => {
        return price - (price * (discount / 100));
    };

    // const discountedPrice = calculateDiscountedPrice(data.harga_pendapatan, data.diskon_pendapatan);

    const sumPengeluaran = (array) => {
        return array.reduce((a, b) => a + b, 0)
    }

    const formatDateTime = (dateTimeString) => {
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        };
        const locale = "id-ID";
        return new Date(dateTimeString).toLocaleDateString(locale, options);
    };

    return (
        <Document size="A4">
            {
                data?.map((item, index) => {
                    const pemasukan = calculateDiscountedPrice(item.harga_pendapatan, item.diskon_pendapatan);
                    const pengeluaran = sumPengeluaran([item.homestay_pengeluaran, item.pemandu_pengeluaran, item.sewa_pengeluaran, item.kegiatan_pengeluaran, item.keamanan_pengeluaran, item.konsumsi_pengeluaran, item.lain_lain_pengeluaran])
                    return (
                        <Page key={index} style={styles.body}>

                            <View>
                                <Text style={styles.header}>{`Laporan Pendapatan Event ${monthNames[new Date(item.tanggal).getMonth()]} ${new Date(item.tanggal).getFullYear()}`}</Text>
                                <View style={styles.flexContainer}>
                                    <View style={[styles.flexItem]}>
                                        <Text>Tanggal</Text>
                                    </View>
                                    <View style={[styles.flexItem, { textAlign: 'right' }]}>
                                        <Text>{item.penanggung_jawab}</Text>
                                    </View>
                                </View>
                                <View style={styles.flexContainer}>
                                    <View style={[styles.flexItem]}>
                                        <Text>Nama Tamu</Text>
                                    </View>
                                    <View style={[styles.flexItem, { textAlign: 'right' }]}>
                                        <Text>{item.nama_tamu}</Text>
                                    </View>
                                </View>
                                <View style={styles.flexContainer}>

                                    <View style={[styles.flexItem]}>
                                        <Text>Tanggal</Text>
                                    </View>
                                    <View style={[styles.flexItem, { textAlign: 'right' }]}>
                                        <Text>{formatDateTime(item.tanggal)}</Text>
                                    </View>
                                </View>



                                {/* Pendapatan */}
                                <View style={styles.PendapataContainer}>
                                    <Text style={styles.subTitle}>Pendapatan</Text>
                                    {/* Harga */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Harga</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.harga_pendapatan?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Diskon */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Diskon</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{`${item.diskon_pendapatan}%`}</Text>
                                            </View>
                                        </View>

                                    </View>
                                    <View style={[styles.divider, { width: '30%' }]}></View>
                                    {/* Total Pemasukan */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <Text style={{
                                                fontSize: 12,
                                                fontFamily: 'Times-Bold',
                                                wordWrap: 'break-word'
                                            }} >Total Pemasukan</Text>
                                        </View>
                                        <View style={[styles.flexItem, {
                                            display: 'flex', flexDirection: 'row', textAlign: 'right', fontSize: 12,
                                            wordWrap: 'break-word',
                                            fontFamily: 'Times-Bold'
                                        }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(pemasukan?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                {/* Pengeluaran */}
                                <View style={styles.PendapataContainer}>
                                    <Text style={styles.subTitle}>Pengeluaran</Text>
                                    {/* Akomodasi */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Akomodasi</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.homestay_pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Pemandu */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Pemandu</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.pemandu_pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Sewa */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Sewa</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.sewa_pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Kegiatan */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Kegiatan</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.kegiatan_pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Keamanan */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Keamanan</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.keamanan_pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Konsumsi */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Konsumsi</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.konsumsi_pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>

                                    </View>

                                    {/* Lain-lain */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <View style={styles.bullet}>
                                                <Text>{'\u2022' + " "}</Text>
                                            </View>
                                            <Text>Lain-lain</Text>
                                        </View>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', textAlign: 'right' }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(item.lain_lain_pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>


                                    <View style={[styles.divider, { width: '30%' }]}></View>
                                    {/* Total Pengeluaran */}
                                    <View style={styles.flexContainer}>
                                        <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                            <Text style={{
                                                fontSize: 12,
                                                fontFamily: 'Times-Bold',
                                                wordWrap: 'break-word'
                                            }} >Total Pengeluaran</Text>
                                        </View>
                                        <View style={[styles.flexItem, {
                                            display: 'flex', flexDirection: 'row', textAlign: 'right', fontSize: 12,
                                            wordWrap: 'break-word',
                                            fontFamily: 'Times-Bold'
                                        }]}>
                                            <View style={{ width: '60%' }}>
                                                <Text>Rp </Text>
                                            </View>
                                            <View style={{ width: '40%' }}>
                                                <Text>{fCurrencyNoRp(pengeluaran?.toString().replace('.', ','))}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={[styles.divider, { width: '35%' }]}></View>
                                <View style={styles.flexContainer}>
                                    <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row' }]}>
                                        <Text style={{
                                            fontSize: 12,
                                            wordWrap: 'break-word',
                                            fontFamily: 'Times-Bold'
                                        }} >Total Saldo</Text>
                                    </View>
                                    <View style={[styles.flexItem, {
                                        display: 'flex', flexDirection: 'row', textAlign: 'right', fontSize: 12,
                                        wordWrap: 'break-word',
                                        fontFamily: 'Times-Bold'
                                    }]}>
                                        <View style={{ width: '60%' }}>
                                            <Text>Rp </Text>
                                        </View>
                                        <View style={{ width: '40%' }}>
                                            <Text>{fCurrencyNoRp(pemasukan - pengeluaran?.toString().replace('.', ','))}</Text>
                                        </View>
                                    </View>

                                </View>
                            </View>

                        </Page>
                    )
                }
                )
            }
            {/* {pages.map((page, index) => {
                return (
                    <Page key={index} style={{ ...styles.body, backgroundColor: pageColors[index] }}>
                        <Text style={styles.header} fixed></Text>
                        <Image style={styles.image} src={page.image} alt="" />
                        <Text style={styles.text}>
                            {page.text}
                        </Text>
                        <Text
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} / ${totalPages}`
                            }
                        />
                    </Page>
                )
            })} */}

        </Document>
    );
}

export default PDFRekapEvent;