import ReactPDF, { Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer'
import { fCurrencyIDR } from '../../features/numberFormat';
import { fKeterangan } from '../../features/formatKeterangan';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: "Times-Roman"
    },
    header: {
        fontSize: 14,
        marginBottom: 20,
        textAlign: "center",
        letterSpacing: 0.32,
        wordWrap: "break-word",
        fontFamily: 'Times-Bold'
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        display: 'flex',
        justifyContent: 'center',
        padding: 3
    },
    tableCell: {
        textAlign: 'center',
        fontSize: 12,
    }
});

const PDFRekapBulan = ({ rows, formatDateTime, totalDebit, totalKredit, totalSaldo, formattedLabel }) => (
    <Document>
        <Page style={styles.body}>
            <View style={styles.header} >
                <Text>Laporan Keuangan Bulanan Desa Wisata Jatiluwih</Text>
                <Text>{formattedLabel}</Text>
            </View>

            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: '12%' }]}>
                        <Text style={styles.tableCell}>Tanggal</Text>
                    </View>
                    <View style={[styles.tableCol, { width: '8%' }]}>
                        <Text style={styles.tableCell}>Akun</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Keterangan</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Debit</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Kredit</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Saldo</Text>
                    </View>
                </View>
                {rows.map((row) => (
                    <View key={row.id} style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: '12%' }]}>
                            <Text style={styles.tableCell}>{formatDateTime(row.date)}</Text>
                        </View>
                        <View style={[styles.tableCol, { width: '8%' }]}>
                            <Text style={styles.tableCell}>{row.kode} </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={[styles.tableCell, { textAlign: 'left' }]}> 
                            {row.keterangan && typeof row.keterangan === 'string'? fKeterangan(row.keterangan): "-"}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{row.jumlahPendapatan !== null ? fCurrencyIDR(row.jumlahPendapatan) : "-"}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{row.jumlahPengeluaran !== null ? fCurrencyIDR(row.jumlahPengeluaran) : "-"}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{fCurrencyIDR(row.saldo)}</Text>
                        </View>
                    </View>
                ))}
                <View style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: '40%' }]}>
                        <Text style={[styles.tableCell, { fontFamily: 'Times-Bold' }]}>Jumlah</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontFamily: 'Times-Bold' }]}>{fCurrencyIDR(totalDebit)} </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontFamily: 'Times-Bold' }]}>{fCurrencyIDR(totalKredit)} </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontFamily: 'Times-Bold' }]}>{fCurrencyIDR(totalSaldo)} </Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);

export default PDFRekapBulan;