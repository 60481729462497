import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaket } from "../features/paketSlice";
import Layout from "./Layout";
import FormInputEvent from "../components/FormInputEvent";
import { useNavigate } from "react-router-dom";

const InputEvent = () => {
  const { paket } = useSelector((state) => state.paket);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);
  const [dataEvent, setDataEvent] = useState(paket.data);

  useEffect(() => {
    dispatch(getPaket());
  }, [dispatch]);

  useEffect(() => {
    if (paket.data) {
      setDataEvent(paket.data);
    }
  }, [paket.data]);

  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);

  return (
    <Layout>
      <FormInputEvent dataEvent={dataEvent} />
    </Layout>
  );
};

export default InputEvent;
