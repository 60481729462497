import React, { useEffect, useState } from "react";

import { Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import GridPrice from "./InvoicePrice/GridPrice";
import GridTotal from "./InvoicePrice/GridTotal";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useDispatch, useSelector } from "react-redux";
import { setPdfUrl } from "../features/eventSlice";
import { fCurrencyIDR, fCurrencyNoRp } from "../features/numberFormat";
import TableAction from "./TableAction";
import ViewPdfButton from "./Button/ViewPDF";

const LaporanEvent = ({ data, monthNames }) => {


    const pengeluaran = [data.homestay_pengeluaran, data.pemandu_pengeluaran, data.sewa_pengeluaran, data.kegiatan_pengeluaran, data.keamanan_pengeluaran, data.konsumsi_pengeluaran, data.lain_lain_pengeluaran]
    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();
    const pdfUrl = useSelector((state) => state.event.pdfUrl);


    const calculateDiscountedPrice = (price, discount) => {
        return price - (price * (discount / 100));
    };

    const discountedPrice = calculateDiscountedPrice(data.harga_pendapatan, data.diskon_pendapatan);
    let sum = pengeluaran.reduce((a, b) => a + b, 0)

    const formatDateTime = (dateTimeString) => {
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        };
        const locale = "id-ID";
        return new Date(dateTimeString).toLocaleDateString(locale, options);
    };

    const handlePdfUrl = (link) => {
        dispatch(setPdfUrl(link))
        setOpen(true)
    }


    useEffect(() => {
        setYear(new Date(data.tanggal).getFullYear())
        setMonth(new Date(data.tanggal).getMonth() + 1)
    }, [data.tanggal])


    return (
        <>
            <Card sx={{ marginTop: 3, background: 'rgba(232, 232, 232, 0.50)' }} elevation={0}>
                <CardContent>
                    <Typography variant="h5" textAlign="center">{`Laporan Pendapatan Event ${monthNames[month - 1]} ${year}`}</Typography>
                    <Box p={3}>
                        <Grid container spacing={1} direction="column" >
                            <Grid item>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item>
                                        <Typography>Penanggung Jawab</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{data.penanggung_jawab}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item>
                                        <Typography>Nama Tamu</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{data.nama_tamu}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item>
                                        <Typography>Tanggal</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{formatDateTime(data.tanggal)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item>
                                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={'center'}>
                                    <Typography variant="h6" fontWeight={600}>Pendapatan</Typography>
                                    <ViewPdfButton handleAction={() => handlePdfUrl(data.upload_bukti)} />
                                </Box>

                                <GridPrice name={'Harga'} value={fCurrencyIDR(data.harga_pendapatan?.toString().replace('.', ','))} />

                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item >
                                        <Typography><FiberManualRecordIcon sx={{ fontSize: 10, mr: 1 }} />Diskon</Typography>
                                    </Grid>
                                    <Grid item display="flex" justifyContent="flex-end">
                                        <Typography>{`${data.diskon_pendapatan}%`}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" mt={1}>
                                    <Grid item md={8} >

                                    </Grid>
                                    <Grid item md={4} >
                                        <Divider sx={{ borderWidth: 2, borderColor: 'black' }} />
                                    </Grid>
                                </Grid>

                                <GridTotal name={'Total Pemasukan'} value={fCurrencyNoRp(discountedPrice?.toString().replace('.', ','))} />
                            </Grid>

                            <Grid item >
                                <Typography variant="h6" fontWeight={600}>Pengeluaran</Typography>

                                <GridPrice name={'Akomodasi'} value={fCurrencyNoRp(data.homestay_pengeluaran?.toString().replace('.', ','))} handleAction={() => handlePdfUrl(data.upload_homestay)} />

                                <GridPrice name={'Pemandu'} value={fCurrencyNoRp(data.pemandu_pengeluaran?.toString().replace('.', ','))} handleAction={() => handlePdfUrl(data.upload_pemandu)} />

                                <GridPrice name={'Sewa'} value={fCurrencyNoRp(data.sewa_pengeluaran?.toString().replace('.', ','))} handleAction={() => handlePdfUrl(data.upload_sewa)} />

                                <GridPrice name={'Kegiatan'} value={fCurrencyNoRp(data.kegiatan_pengeluaran?.toString().replace('.', ','))} handleAction={() => handlePdfUrl(data.upload_kegiatan)} />

                                <GridPrice name={'Keamanan'} value={fCurrencyNoRp(data.keamanan_pengeluaran?.toString().replace('.', ','))} handleAction={() => handlePdfUrl(data.keamanan)} />

                                <GridPrice name={'Konsumsi'} value={fCurrencyNoRp(data.konsumsi_pengeluaran?.toString().replace('.', ','))} handleAction={() => handlePdfUrl(data.upload_konsumsi)} />

                                <GridPrice name={'Lain-lain'} value={fCurrencyNoRp(data.lain_lain_pengeluaran?.toString().replace('.', ','))} handleAction={() => handlePdfUrl(data.upload_bukti_lainnya)} />

                                <Grid container direction="row" mt={1}>
                                    <Grid item md={8} >

                                    </Grid>
                                    <Grid item md={4} >
                                        <Divider sx={{ borderWidth: 2, borderColor: 'black' }} />
                                    </Grid>
                                </Grid>

                                <GridTotal name={'Total Pengeluaran'} value={fCurrencyNoRp(sum.toString().replace('.', ','))} />
                            </Grid>

                        </Grid>
                        <Grid container direction="row" mt={1}>
                            <Grid item md={7} >

                            </Grid>
                            <Grid item md={5} >
                                <Divider sx={{ borderWidth: 2, borderColor: 'black' }} />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" mt={2}>
                            <Grid item md={6}>
                                <Typography variant="h6">{'Total Saldo'}</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item md={6} display="flex" justifyContent="flex-end">
                                        <Typography variant="h5" fontWeight={600}>Rp</Typography>
                                    </Grid>
                                    <Grid item md={6} display="flex" justifyContent="flex-end">
                                        <Typography variant="h5" fontWeight={600}>{fCurrencyNoRp((discountedPrice - sum).toString().replace('.', ','))}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
            <TableAction isOpen={open} onClose={() => setOpen(false)} pdfUrl={pdfUrl} />
        </>
    );
};

export default LaporanEvent;