import React from "react";
import { useSelector } from "react-redux";

const Error404 = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      <h1 className="title">Error</h1>
    </div>
  );
};

export default Error404;
