import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ViewPdfButton from "../Button/ViewPDF";


const GridPrice = ({ name, value, handleAction }) => {
    return (

        <Grid container direction="row" justifyContent="space-between" mt={1}>
            <Grid item md={6}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={'center'} flexGrow={1}>
                    <Grid container direction="row">
                        <Grid item md={3} display="flex" >
                            <Typography><FiberManualRecordIcon sx={{ fontSize: 10, mr: 1 }} />{name}</Typography>
                        </Grid>
                        <Grid item md={2} display="flex" >
                            {
                                handleAction && (
                                    <ViewPdfButton handleAction={handleAction} />
                                )
                            }
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
            <Grid item md={6}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item md={6} display="flex" justifyContent="flex-end">
                        <Typography>Rp</Typography>
                    </Grid>
                    <Grid item md={6} display="flex" justifyContent="flex-end">
                        <Typography>{value}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GridPrice;