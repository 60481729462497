import { Box, Card, Stack, Typography } from '@mui/material';
import { fCurrencyIDR } from '../features/numberFormat';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from "@mui/material/styles";


const AppWidgetSummary = ({ title,title2, title3, total, icon, color = 'primary', sx, ...other }) => {
  const theme = useTheme();
  return (
    <Card
      component={Stack}
      container
      spacing={-1}
      direction="row"
      alignItems="flex-start" // Tetapkan alignItems ke flex-start
      sx={{
        px: 1.5,
        py: 3,
        pb: 3,
        borderRadius: 2,
        ...sx,
      }}
      {...other}
    >
      {icon && (
        <Stack item>
          <Box sx={{ width:'small', height: 'small' }}>{icon}</Box>
          <Typography variant="subtitle2" sx={{ color: 'text.disabled', mt : 5, }}>
          <Typography component={Link} to="/laporan-tahunan" style={{ alignItems: 'center', color: theme.palette.primary.main}}>
            {title2}
          </Typography>
          <Typography component={Link} to="/laporan-event" style={{ alignItems: 'center', color: theme.palette.primary.main}}>
            {title3}
            <ArrowForwardIosIcon sx={{fontSize: 'small', ml:0.5, mt:0.5  }} />
          </Typography>
        </Typography>
        </Stack>
      )}
        <Stack item spacing={0} >
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h5">{fCurrencyIDR(total)}</Typography>
        </Stack>
    </Card>
  );
};
export default AppWidgetSummary;