import Layout from "./Layout";
import RekapLaporan from "../components/RekapLaporan";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CardComponent from "../components/CardComponent";

const RekapLaporanBulanan = () => {
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);

  return (
    <Layout>
      <CardComponent
        title={'Rekap Bulanan'}
        subTitle={'Pilih Laporan Berdasarkan Bulanan'}
        content={
          <RekapLaporan/>
        }
      />
    </Layout>
  );
};

export default RekapLaporanBulanan;