import { IconButton } from "@mui/material";
import { ReactComponent as ViewPdfIcon } from '../../assets/icons/view_pdf.svg'

const ViewPdfButton = ({ handleAction }) => {
    const iconButtonSides = 28;

    return (
        <IconButton onClick={handleAction} size="small" sx={{
            width: iconButtonSides,
            height: iconButtonSides, borderRadius: '3px', backgroundColor: 'primary.main', "&:hover": { backgroundColor: "primary.light" },
        }}>
            <ViewPdfIcon />
        </IconButton>
    );
}

export default ViewPdfButton;