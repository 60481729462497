import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  akunSatu: {
    data: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  akunDua: {
    data: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

export const getAkunSatu = createAsyncThunk("akun/getAkunSatu", () => {
  return axios.get("/akun-satu").then((response) => response.data);
});

export const getAkunDua = createAsyncThunk("akun/getAkunDua", () => {
  return axios.get("/akun-dua").then((response) => response.data);
});

export const createAkunDua = createAsyncThunk(
  "akun/createAkunDua",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/akun-dua", {
        kode: data.kode,
        nama_akun: data.namaAkun,
        keuangan_akun_satu_id: data.idAkunSatu,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const updateAkunDua = createAsyncThunk(
  "akun/updateAkunDua",
  async (data, thunkAPI) => {
    try {
      const response = await axios.patch(`/akun-dua/${data.id}`, {
        kode: data.kode,
        nama_akun: data.namaAkun,
        keuangan_akun_satu_id: data.idAkunSatu,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const deleteAkunDua = createAsyncThunk(
  "akun/deleteAkunDua",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`/akun-dua/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const akunSlice = createSlice({
  name: "akun",
  initialState,
  // reducers: {
  //   reset: (state) => initialState,
  // },
  extraReducers: (builder) => {
    builder.addCase(getAkunSatu.pending, (state) => {
      state.akunSatu.isLoading = true;
    });
    builder.addCase(getAkunSatu.fulfilled, (state, action) => {
      state.akunSatu.isLoading = false;
      state.akunSatu.isSuccess = true;
      state.akunSatu.data = action.payload;
    });
    builder.addCase(getAkunSatu.rejected, (state, action) => {
      state.akunSatu.isLoading = false;
      state.akunSatu.isError = true;
      // state.akunSatu.data = [];
      state.akunSatu.message = action.payload;
    });

    //getAkun
    builder.addCase(getAkunDua.pending, (state) => {
      state.akunDua.isLoading = true;
    });
    builder.addCase(getAkunDua.fulfilled, (state, action) => {
      state.akunDua.isLoading = false;
      state.akunDua.isSuccess = true;
      state.akunDua.data = action.payload;
    });
    builder.addCase(getAkunDua.rejected, (state, action) => {
      state.akunDua.isLoading = false;
      state.akunDua.isError = true;
      // state.akunDua.data = [];
      state.akunDua.message = action.payload;
    });

    //create Akun Dua
    builder.addCase(createAkunDua.pending, (state) => {
      state.akunDua.isLoading = true;
    });
    builder.addCase(createAkunDua.fulfilled, (state, action) => {
      state.akunDua.isLoading = false;
      state.akunDua.isSuccess = true;
      state.akunDua.data.push(action.payload);
    });
    builder.addCase(createAkunDua.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    //update Akun Dua
    builder.addCase(updateAkunDua.pending, (state) => {
      state.akunDua.isLoading = true;
    });
    builder.addCase(updateAkunDua.fulfilled, (state, action) => {
      state.akunDua.isLoading = false;
      state.akunDua.isSuccess = true;
      const updateItem = action.payload;
      const index = state.akunDua.data.findIndex(
        (item) => item.id === updateItem.id
      );
      if (index !== -1) {
        state.akunDua.data[index] = updateItem;
      }
    });
    builder.addCase(updateAkunDua.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    //delete Akun Dua
    builder.addCase(deleteAkunDua.pending, (state) => {
      state.akunDua.isLoading = true;
    });
    builder.addCase(deleteAkunDua.fulfilled, (state, action) => {
      state.akunDua.isLoading = false;
      state.akunDua.isSuccess = true;
      const deleteItem = action.payload;
      const index = state.akunDua.data.findIndex(
        (item) => item.id === deleteItem.id
      );
      state.akunDua.data.splice(index, 1);
    });
    builder.addCase(deleteAkunDua.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

// export const { reset } = akunSlice.actions;
export default akunSlice.reducer;
