import React, { useEffect } from "react";
import Layout from "./Layout";
import AppView from "../components/AppView";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import { getAllEvent } from "../features/eventSlice";
import { getAllTransaksi } from "../features/transaksiSlice";
import { getAkunDua } from "../features/akunSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalSaldo, setTotalSaldo] = useState(0);
  const [incomeChartData, setIncomeChartData] = useState([]);
  const [expenseChartData, setExpenseChartData ] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [eventChartData, setEventChartData] = useState([]);

  const currentYear = new Date().getFullYear();
  

  useEffect(() => {
    const fetchData = async () => {
      try {

        // Dispatch both actions and await their completion
        const [transaksiResponse, akunDuaResponse, eventResponse] = await Promise.all([
          dispatch(getAllTransaksi()),
          dispatch(getAkunDua()),
          dispatch(getAllEvent())
        ]);


        const transactions = Array.isArray(transaksiResponse.payload.data)
        ? transaksiResponse.payload.data
        : [];
        const akunDuaData = Array.isArray(akunDuaResponse.payload)
        ? akunDuaResponse.payload
        : [];

        // Filter transactions for the current year
        const transactionsForCurrentYear = transactions.filter(
          (transaction) =>
            new Date(transaction.date).getFullYear() === currentYear
        );

        const incomeTransactions = transactionsForCurrentYear.filter(
          (transaction) => {
            // Find the corresponding akun_dua for the current transaction
            const correspondingAkunDua = akunDuaData.find(
              (akun) => akun.id === transaction.keuangan_akun_dua_id
            );
        
            // Assuming keuangan_akun_satu_id in akun_dua corresponds to id in transactions
            return correspondingAkunDua?.keuangan_akun_satu_id === 1; // Assuming id 1 is for income
          }
        );
                
        // Assuming keuangan_akun_dua_id in transactions corresponds to id in akun_dua
        const expenseTransactions = transactionsForCurrentYear.filter(
          (transaction) => {
            // Find the corresponding akun_dua for the current transaction
            const correspondingAkunDua = akunDuaData.find(
              (akun) => akun.id === transaction.keuangan_akun_dua_id
            );
        
            // Assuming keuangan_akun_satu_id in akun_dua corresponds to id in transactions
            return correspondingAkunDua?.keuangan_akun_satu_id === 2; // Assuming id 2 is for expenses
          }
        );
        
        const totalIncome = incomeTransactions.reduce(
          (sum, transaction) => sum + (transaction.jumlah || 0),
          0
        );

        const totalExpenses = expenseTransactions.reduce(
          (sum, transaction) => sum + (transaction.jumlah || 0),
          0
        );

        // Calculate totalSaldo
        const totalSaldo = totalIncome - totalExpenses;

        const calculateDiscountedPrice = (price, discount) => {
          return price - (price * (discount / 100));
        };
        
        const events = Array.isArray(eventResponse.payload) ? eventResponse.payload : [];
        const eventDataYear = {};
        let eventData = 0;

        events.forEach((event) => {
          const year = new Date(event.tanggal).getFullYear();

        if (year === currentYear) {
          const pengeluaran = [
            event.homestay_pengeluaran,
            event.pemandu_pengeluaran,
            event.sewa_pengeluaran,
            event.kegiatan_pengeluaran,
            event.keamanan_pengeluaran,
            event.konsumsi_pengeluaran,
            event.lain_lain_pengeluaran,
          ];

          const discountedPrice = calculateDiscountedPrice(event.harga_pendapatan, event.diskon_pendapatan);
  
            // Calculate sum of pengeluaran array
            let sum = pengeluaran.reduce((a, b) => a + b, 0);

            // Accumulate the event.harga_pendapatan into eventDataByYear
            if (!eventDataYear[currentYear]) {
              eventDataYear[currentYear] = 0;
            }
            
            // Add the totalAdjusted to eventDataByYear
            eventData += discountedPrice - sum;
            eventDataYear[currentYear] += discountedPrice - sum;
          }
        });

        setTotalIncome(totalIncome);
        setTotalExpenses(totalExpenses);
        setTotalSaldo(totalSaldo);
        setEventData(eventData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
      }
    };

    fetchData();
  }, [dispatch, currentYear]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [transaksiResponse, akunDuaResponse, eventResponse] = await Promise.all([
          dispatch(getAllTransaksi()),
          dispatch(getAkunDua()),
          dispatch(getAllEvent()),
        ]);
  
        const transactions = Array.isArray(transaksiResponse.payload.data)
          ? transaksiResponse.payload.data
          : [];
        const akunDuaData = Array.isArray(akunDuaResponse.payload)
          ? akunDuaResponse.payload
          : [];
  
        // Group income transactions by year
        const incomeDataByYear = {};
        transactions.forEach((transaction) => {
          // Find the corresponding akun_dua for the current transaction
          const correspondingAkunDua = akunDuaData.find(
            (akun) => akun.id === transaction.keuangan_akun_dua_id
          );
  
          // Assuming keuangan_akun_satu_id in akun_dua corresponds to id in transactions
          if (correspondingAkunDua && correspondingAkunDua.keuangan_akun_satu_id === 1) {
            const year = new Date(transaction.date).getFullYear();
            if (!incomeDataByYear[year]) {
              incomeDataByYear[year] = 0;
            }
            incomeDataByYear[year] += transaction.jumlah || 0;
          }
        });
  
        // Group expense transactions by year
        const expenseDataByYear = {};
        transactions.forEach((transaction) => {
          // Find the corresponding akun_dua for the current transaction
          const correspondingAkunDua = akunDuaData.find(
            (akun) => akun.id === transaction.keuangan_akun_dua_id
          );
  
          // Assuming keuangan_akun_satu_id in akun_dua corresponds to id in transactions
          if (correspondingAkunDua && correspondingAkunDua.keuangan_akun_satu_id === 2) {
            const year = new Date(transaction.date).getFullYear();
            if (!expenseDataByYear[year]) {
              expenseDataByYear[year] = 0;
            }
            expenseDataByYear[year] += transaction.jumlah || 0;
          }
        });

        const calculateDiscountedPrice = (price, discount) => {
          return price - (price * (discount / 100));
        };
        
        const events = Array.isArray(eventResponse.payload) ? eventResponse.payload : [];
        const eventDataYear = {};
        
        events.forEach((event) => {
          const year = new Date(event.tanggal).getFullYear();
        
          const pengeluaran = [
            event.homestay_pengeluaran,
            event.pemandu_pengeluaran,
            event.sewa_pengeluaran,
            event.kegiatan_pengeluaran,
            event.keamanan_pengeluaran,
            event.konsumsi_pengeluaran,
            event.lain_lain_pengeluaran,
          ];
        
          const discountedPrice = calculateDiscountedPrice(event.harga_pendapatan, event.diskon_pendapatan);
        
          // Calculate sum of pengeluaran array
          let sum = pengeluaran.reduce((a, b) => a + b, 0);
        
          // Accumulate the event.harga_pendapatan into eventDataByYear
          if (!eventDataYear[year]) {
            eventDataYear[year] = 0;
          }
        
          // Add the totalAdjusted to eventDataByYear
        
          eventDataYear[year] += discountedPrice - sum;
        });
  
        // Create label and value pairs for income
        const incomeChartData = Object.entries(incomeDataByYear)
        .sort(([labelA], [labelB]) => labelB - labelA)
        .map(([label, value]) => ({
          label: label,
          value: value,
        }));
  
        // Create label and value pairs for expenses
        const expenseChartData = Object.entries(expenseDataByYear)
        .sort(([labelA], [labelB]) => labelB - labelA)
        .map(([label, value]) => ({
          label: label,
          value: value,
        }));

        const eventChartData = Object.entries(eventDataYear)
        .sort(([labelA], [labelB]) => labelB - labelA)
        .map(([label, value]) => ({
          label: label,
          value: value,
        }));
  
        // Set the state or pass the data to your chart component
        setIncomeChartData(incomeChartData);
        setExpenseChartData(expenseChartData);
        setEventChartData(eventChartData);
  
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
      }
    };
  
    fetchData();
  }, [dispatch, currentYear]);


  
  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);


  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);

  

  return (
    <Layout>
      <AppView 
       currentYearBalance={totalSaldo}
       totalIncome={totalIncome}
       totalExpenses={totalExpenses}
       incomeChartData={incomeChartData}
       expenseChartData={expenseChartData}
       eventData={eventData}
       eventChartData={eventChartData}
      />
    </Layout>
  );
};

export default Dashboard;
