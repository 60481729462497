import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ModalInput({
  open,
  handleClose,
  editAkun,
  addAkun,
  dataAkunDua,
  setDataAkunDua,
  akunSatu,
  formValidation
}) {



  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Tambah {akunSatu.nama_akun}</DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <DialogContentText mb={2}>
            {`Mohon lengkapi data berikut untuk menambah ${akunSatu.nama_akun} baru`}
          </DialogContentText>
          {akunSatu.nama_akun === "event" ? (
            <Box>
              <InputLabel
                required
                htmlFor="nama_paket"
                error={formValidation.namaPaket?.error}
                sx={{ color: 'black', fontWeight: 400 }}
              >
                Nama
              </InputLabel>
              <TextField
                required
                fullWidth
                margin="dense"
                placeholder="Masukkan Nama Paket"
                error={formValidation.namaPaket?.error}
                helperText={formValidation.namaPaket?.helperText}
                name="nama_paket"
                type="nama_paket"
                id="nama_paket"
                value={dataAkunDua.namaPaket}
                onChange={(event) =>
                  setDataAkunDua({
                    ...dataAkunDua,
                    namaPaket: event.target.value,
                  })
                }
              />
            </Box>
          ) : (
            <Box>
              <Grid container direction='column' rowSpacing={2} >
                <Grid item>
                  <InputLabel
                    required
                    htmlFor="nama_pj"
                    error={formValidation.kode?.error}
                    sx={{ color: 'black', fontWeight: 500 }}
                  >
                    Kode
                  </InputLabel>
                  <TextField
                    margin="dense"
                    size="small"
                    placeholder="Masukkan Kode"
                    fullWidth
                    id="kode"
                    name="kode"
                    error={formValidation.kode?.error}
                    helperText={formValidation.kode?.helperText}
                    autoFocus
                    value={dataAkunDua.kode}
                    onChange={(event) =>
                      setDataAkunDua({
                        ...dataAkunDua,
                        kode: event.target.value.replace(/\D/g, ""),
                      })
                    }
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </Grid>
                <Grid item>
                  <InputLabel
                    required
                    htmlFor="nama_pj"
                    error={formValidation.namaAkun?.error}
                    sx={{ color: 'black', fontWeight: 400 }}
                  >
                    Nama
                  </InputLabel>

                  <TextField
                    size="small"
                    margin="dense"
                    error={formValidation.namaAkun?.error}
                    helperText={formValidation.namaAkun?.helperText}
                    fullWidth
                    name="nama_akun"
                    placeholder="Masukkan Nama"
                    type="nama_akun"
                    id="nama_akun"
                    value={dataAkunDua.namaAkun}
                    onChange={(event) =>
                      setDataAkunDua({
                        ...dataAkunDua,
                        namaAkun: event.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>


            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "#7B57E0" }} onClick={handleClose}>
          Batal
        </Button>
        <Button
          sx={{ background: "#7B57E0" }}
          variant="contained"
          onClick={dataAkunDua.status === "update" ? editAkun : addAkun}
        >
          {dataAkunDua.status === "update" ? "Edit" : "Tambah"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
