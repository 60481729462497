import { FormControl, MenuItem, TextField } from "@mui/material";

const InputSelect = ({ id, error, helperText, value, onChange, options }) => {
  // const [label, setLabel] = useState("Pilih Jenis Akun");
  return (
    <>
      <FormControl fullWidth>
        <TextField
          size="small"
          select
          error={error}
          helperText={helperText}
          // label={label}
          id={id}
          placeholder="Pilih Jenis Akun"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          // onFocus={() => setLabel("")}
        >
          {options.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {id === "akun" ? item.nama_akun : item.nama_paket}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </>
  );
};

export default InputSelect;
