import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../enviroment";

const initialState = {
  event: {
    data: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  price: {
    total: 0,
    discount: 0,
  },
  pengeluaran: {
    dataSum: [],
    total: 0,
  },
  pdfUrl: ``,
};

export const createEvent = createAsyncThunk(
  "event/createEvent",
  async (data, thunkAPI) => {
    try {
      const formData = new FormData();
      const dataAppend = [
        {
          name: "keuangan_paket_id",
          data: data.paket,
        },

        {
          name: "penanggung_jawab",
          data: data.namaPj,
        },

        {
          name: "nama_tamu",
          data: data.namaTamu,
        },

        {
          name: "tanggal",
          data: data.date,
        },

        {
          name: "harga_pendapatan",
          data: data.harga,
        },

        {
          name: "diskon_pendapatan",
          data: data.disc,
        },

        {
          name: "upload_bukti",
          data: data.uploadPendapatan,
        },

        {
          name: "homestay_pengeluaran",
          data: data.akomodasi,
        },
        {
          name: "upload_homestay",
          data: data.uploadAkomodasi,
        },
        {
          name: "pemandu_pengeluaran",
          data: data.pemandu,
        },
        {
          name: "upload_pemandu",
          data: data.uploadPemandu,
        },
        {
          name: "sewa_pengeluaran",
          data: data.sewa,
        },
        {
          name: "upload_sewa",
          data: data.uploadSewa,
        },
        {
          name: "kegiatan_pengeluaran",
          data: data.kegiatan,
        },
        {
          name: "upload_kegiatan",
          data: data.uploadKegiatan,
        },
        {
          name: "keamanan_pengeluaran",
          data: data.keamanan,
        },
        {
          name: "upload_keamanan",
          data: data.uplaodKeamanan,
        },
        {
          name: "konsumsi_pengeluaran",
          data: data.konsumsi,
        },
        {
          name: "upload_konsumsi",
          data: data.uploadKonsumsi,
        },
        {
          name: "lain_lain_pengeluaran",
          data: data.lainLain,
        },
        {
          name: "upload_bukti_lainnya",
          data: data.uploadLainlain,
        },
      ];

      dataAppend.forEach((element) =>
        formData.append(element.name, element.data)
      );

      const response = await axios.post(`/keuangan-event`, formData);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const getAllEvent = createAsyncThunk("event/getEvent", () => {
  return axios.get("/keuangan-event").then((response) => response.data.data);
});

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setTotal: (state, action) => {
      state.price.total = action.payload;
    },
    setDiscount: (state, action) => {
      state.price.discount = action.payload;
    },
    calculateDiscountedPrice: (state) => {
      state.price.total = parseFloat(
        state.price.total - state.price.total * (state.price.discount / 100)
      ).toFixed(2);
    },
    sumPengeluaran: (state, action) => {
      state.pengeluaran.total = parseFloat(
        action.payload.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      );
    },
    setPdfUrl: (state, action) => {
      state.pdfUrl = `${environment.baseUrl}/pdfs/${encodeURIComponent(
        action.payload
      )}`;
    },
  },
  extraReducers: (builder) => {
    //getEvent
    builder.addCase(getAllEvent.pending, (state) => {
      state.event.isLoading = true;
    });
    builder.addCase(getAllEvent.fulfilled, (state, action) => {
      state.event.isLoading = false;
      state.event.isSuccess = true;
      state.event.data = action.payload;
    });
    builder.addCase(getAllEvent.rejected, (state, action) => {
      state.event.isLoading = false;
      state.event.isError = true;
      // state.event.data = [];
      state.event.message = action.payload;
    });

    //create Event
    builder.addCase(createEvent.pending, (state) => {
      state.event.isLoading = true;
    });
    builder.addCase(createEvent.fulfilled, (state, action) => {
      state.event.isLoading = false;
      state.event.isSuccess = true;
      if (!Array.isArray(state.event.data)) {
        state.event.data = [];
      }
      state.event.data.push(action.payload);
    });
    builder.addCase(createEvent.rejected, (state, action) => {
      state.event.isLoading = false;
      state.event.isError = true;
      state.event.message = action.payload;
    });
  },
});

export const {
  reset,
  setDiscount,
  setTotal,
  calculateDiscountedPrice,
  setDataPengeluaran,
  sumPengeluaran,
  setPdfUrl,
} = eventSlice.actions;
export default eventSlice.reducer;
