// import { useRouter } from "next/router";
import React, { useState } from "react";
import {
  Grid,
  Box,
  InputLabel,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { createTransaksiAkun, reset } from "../features/transaksiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAkunSatu, getAkunDua } from "../features/akunSlice"
import CardComponent from "./CardComponent";
import InputCurrency from "./FormInput/InputCurrency";
import InputFile from "./FormInput/InputFile";
import { ReactComponent as SaveIcon } from "../assets/icons/save.svg";
import InputSelect from "./FormInput/InputSelect";
import ModalNotifikasi from "./ModalNotifikasi";
import AlertBar from "./AlertBar";
import { ReactComponent as AskIcon } from "../assets/icons/ask_warning.svg";

const FormInputTransaksi = ({status, akunSatuData, dataInput, setDataInput }) => {
  const INPUT_FORM = {
    date: "",
    idAkunDua: "",
    jumlah: 0,
    keterangan:{
      ketInput:"", 
      wniDewasa:"", 
      wniAnakAnak:"", 
      wnaDewasa:"", 
      wnaAnakAnak:"",
      rodaEnam:"",
      rodaEmpat:"",
      rodaDua:"",
    },
    uploadFile: "",
  };

  const EMPTY_FORM_VALIDATION = {
    date: { error: false, helperText: "" },
    idAkunDua: { error: false, helperText: "" },
    jumlah: { error: false, helperText: "" },
    keterangan: { error: false, helperText: "" },
    uploadFile: { error: false, helperText: "" },
  };

  const dispatch = useDispatch();
  // const [dataInput, setDataInput] = useState(data);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [formValidation, setFormValidation] = useState(EMPTY_FORM_VALIDATION);
  const {akunDua } = useSelector((state) => state.akun);
  const [dataAkunDua, setDataAkunDua] = useState([]);

  useEffect(() => {
    dispatch(getAkunSatu());
    dispatch(getAkunDua());
  }, [dispatch]);

  useEffect(() => {
    if (akunSatuData) {
      setDataAkunDua(
        akunDua.data.filter((item) => item.keuangan_akun_satu_id == akunSatuData?.id)
      );
    }
  }, [akunDua, akunSatuData]);


  const handleClose = () => {
    setOpen(false);
  };

  const validateForm = () => {
    var formValidation = EMPTY_FORM_VALIDATION;
    var isError = false;

    if (dataInput.date == 0) {
      formValidation.date.error = true;
      formValidation.date.helperText = "Mohon masukkan Tanggal";
      isError = true;
    }

    if (dataInput.idAkunDua == "") {
      formValidation.idAkunDua.error = true;
      formValidation.idAkunDua.helperText = "Mohon Pilih Akun";
      isError = true;
    }
    if (dataInput.jumlah == "") {
      formValidation.jumlah.error = true;
      formValidation.jumlah.helperText = "Mohon Masukan Jumlah";
      isError = true;
    }
    if (dataInput.uploadFile == "") {
      formValidation.uploadFile.error = true;
      formValidation.uploadFile.helperText = `Mohon Upload Bukti ${akunSatuData?.nama_akun}`;
      isError = true;
    }
    if (dataInput.keterangan == "") {
      formValidation.keterangan.error = true;
      formValidation.keterangan.helperText = "Mohon Masukan Keterangan";
      isError = true;
    }

    setFormValidation(formValidation);
    return !isError;
  };

  const handleOpen = () => {
    const isValid = validateForm();
    if (isValid) {
      setOpen(true);
    } else {
      setOpenAlert((prev) => !prev);
      setMessage(`Mohon Periksa Kembali Data Anda`);
      setSeverity("error");
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

    if (Object.values(dataInput.keterangan).some((val) => val !== "")) {
      const keteranganJson = JSON.stringify(dataInput.keterangan);

      const combinedData = {
        ...dataInput,
        keterangan: keteranganJson,
      };

      // Lakukan sesuatu dengan data yang telah dihasilkan

      // Lakukan submit dengan data yang sudah diubah
      dispatch(createTransaksiAkun(combinedData));

      // dispatch(createTransaksiAkun(dataInput));
      setDataInput(INPUT_FORM);
      handleClose();
      setOpenAlert((prev) => !prev);
      setMessage(`${akunSatuData?.nama_akun} Berhasil Disimpan`);
      setSeverity("success");
      dispatch(reset());
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      console.error("Tidak ada data yang diisi pada keterangan");
    }
    } catch (error) {
      setOpenAlert((prev) => !prev);
      setMessage(`Ada Kesalahan Saat Menyimpan ${akunSatuData?.nama_akun}`);
      setSeverity("error");
      console.log(error);
    }
  };


  const handleInputChange = (field, value) => {
    setDataInput((prevData) => {
      // Buat salinan dari dataInput
      const newData = { ...prevData };
  
      // Periksa apakah nilai tidak kosong
      if (value !== "") {
        newData.keterangan = {
          ...newData.keterangan,
          [field]: value,
        };
      } else {
        // Jika nilai kosong, hapus properti dari objek keterangan
        const { [field]: _, ...newKeterangan } = newData.keterangan;
        newData.keterangan = newKeterangan;
      }
  
      return newData;
    });
  };
  

  const renderInputField = (label, fieldName, value, onChange, status) => (
    <Grid
      key={fieldName}
      mt={{ xs: 1 }}
      container
      spacing={{ xs: 0, md: 3 }}
      direction={{ sm: "column", md: "row" }}
    >
      <Grid item xs={6} md={3}>
        <InputLabel
          htmlFor={fieldName}
        >
          {label}
        </InputLabel>
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          size="small"
          fullWidth
          placeholder={label}
          id={fieldName}
          type="number"
          value={value}
          onChange={(e) => onChange(fieldName, e.target.value)}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <AlertBar
        open={openAlert}
        setOpen={setOpenAlert}
        message={message}
        severity={severity}
      />

      <CardComponent
        title={akunSatuData?.nama_akun}
        subTitle={`${status === "edit" ? "Ubah" : "Tambah"} ${akunSatuData?.nama_akun}`}
        content={
          <Box px={3}>
            <ModalNotifikasi
              open={open}
              handleClose={handleClose}
              buttonAction={handleSubmit}
              status={"add"}
              title={'Konfirmasi Menyimpan Data'}
              icon={<AskIcon />}
              contentText={"Apakah data yang anda masukkan sudah benar?"}
            />


            <Grid
              mt={{ xs: 1 }}
              container
              spacing={{ xs: 0, md: 3 }}
              direction={{ sm: "column", md: "row" }}
            >
              <Grid item xs={12} md={3}>
                <InputLabel
                  required
                  htmlFor="nik"
                  error={formValidation.date.error}
                >
                  Tanggal
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={status === "edit" ? 8 : 3}>
                <TextField
                  error={formValidation.date.error}
                  helperText={formValidation.date.helperText}
                  size="small"
                  fullWidth
                  placeholder="Masukan NIK Pemohon"
                  id="nik"
                  type="date"
                  value={dataInput.date}
                  onChange={(e) => {
                    setDataInput({ ...dataInput, date: e.target.value });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              mt={{ xs: 2, md: 0.1 }}
              container
              spacing={{ xs: 0, md: 3 }}
              direction={{ sm: "column", md: "row" }}
            >
              <Grid item xs={12} md={3}>
                <InputLabel
                  required
                  htmlFor="akun"
                  error={formValidation.idAkunDua.error}
                >
                  Akun
                </InputLabel>
              </Grid>

              <Grid item xs={12} md={status === "edit" ? 8 : 3}>
                <InputSelect
                  id="akun"
                  error={formValidation.idAkunDua.error}
                  helperText={formValidation.idAkunDua.helperText}
                  value={dataInput.idAkunDua}
                  options={dataAkunDua}
                  onChange={(value) =>
                    setDataInput({ ...dataInput, idAkunDua: value })
                  }
                />
              </Grid>
            </Grid>

            <Grid
              mt={{ xs: 1 }}
              container
              spacing={{ xs: 0, md: 3 }}
              direction={{ sm: "column", md: "row" }}
            >
              <Grid item xs={12} md={3}>
                <InputLabel
                  required
                  htmlFor="nik"
                  error={formValidation.jumlah.error}
                >
                  Jumlah
                  {/* {dataInput.jumlah} */}
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={status === "edit" ? 8 : 3}>
                <InputCurrency
                  error={formValidation.jumlah.error}
                  helperText={formValidation.jumlah.helperText}
                  status={status}
                  value={dataInput.jumlah}
                  onChange={(value) =>
                    setDataInput({ ...dataInput, jumlah: value })
                  }
                  placeholder={"Masukan Jumlah"}
                  id={"jumlah"}
                />
              </Grid>
            </Grid>

            <Grid
              mt={{ xs: 1 }}
              container
              spacing={{ xs: 0, md: 3 }}
              direction={{ sm: "column", md: "row" }}
            >
              <Grid item xs={12} md={3}>
                <InputLabel
                  required
                  htmlFor="upload-file"
                  error={formValidation.uploadFile.error}
                >
                  {`Upload Bukti ${akunSatuData?.nama_akun}`}
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={status === "edit" ? 8 : 3}>
                <InputFile
                  id="upload-file"
                  error={formValidation.uploadFile.error}
                  helperText={formValidation.uploadFile.helperText}
                  fileName={dataInput.uploadFile ? dataInput.uploadFile?.name : dataInput?.uploadData}
                  onChange={(value) =>
                    setDataInput({ ...dataInput, uploadFile: value })
                  }
                />
              </Grid>
            </Grid>

            {akunSatuData?.id === 2  && (
            <Grid
              mt={{ xs: 1 }}
              container
              spacing={{ xs: 0, md: 3 }}
              direction={{ sm: "column", md: "row" }}
            >
              <Grid item xs={12} md={3}>
                <InputLabel
                  required
                  htmlFor="nik"
                  error={formValidation.keterangan.error}
                >
                  Keterangan
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  error={formValidation.keterangan.error}
                  helperText={formValidation.keterangan.helperText}
                  size="small"
                  fullWidth
                  placeholder="Keterangan"
                  id="number"
                  type="text"
                  multiline
                  rows={4}
                  value={dataInput.keterangan.ketInput}
                  onChange={(e) => handleInputChange("ketInput", e.target.value)}
                />
              </Grid>
            </Grid>
            )}
            {akunSatuData?.id === 1  && (
            
            <>
                {renderInputField("WNI Dewasa", "wniDewasa", dataInput.keterangan.wniDewasa, handleInputChange)}
                {renderInputField("WNI Anak-Anak", "wniAnakAnak", dataInput.keterangan.wniAnakAnak, handleInputChange)}
                {renderInputField("WNA Dewasa", "wnaDewasa", dataInput.keterangan.wnaDewasa, handleInputChange)}
                {renderInputField("WNA Anak-Anak", "wnaAnakAnak", dataInput.keterangan.wnaAnakAnak, handleInputChange)}
                {renderInputField("Parkir Roda 6", "rodaEnam", dataInput.keterangan.rodaEnam, handleInputChange)}
                {renderInputField("Parkir Roda 4", "rodaEmpat", dataInput.keterangan.rodaEmpat, handleInputChange)}
                {renderInputField("Parkir Roda 2", "rodaDua", dataInput.keterangan.rodaDua, handleInputChange)}

                <Grid
                  mt={{ xs: 1 }}
                  container
                  spacing={{ xs: 0, md: 3 }}
                  direction={{ sm: "column", md: "row" }}
                >
                  <Grid item xs={12} md={3}>
                    <InputLabel
                      required
                      htmlFor="nik"
                      error={formValidation.keterangan.error}
                    >
                      Keterangan
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      error={formValidation.keterangan.error}
                      helperText={formValidation.keterangan.helperText}
                      size="small"
                      fullWidth
                      placeholder="Keterangan"
                      id="number"
                      type="text"
                      multiline
                      rows={4}
                      value={dataInput.keterangan.ketInput}
                      onChange={(e) => handleInputChange("ketInput", e.target.value)}
                    />
                  </Grid>
                </Grid>
            </>
           )}
          </Box>
        }
        action={
          <Box display="flex" justifyContent="flex-end">
            {
              status === "add" &&
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => handleOpen()}
            >
              Simpan
            </Button>
            }
          </Box>
        }
      />
    </>
  );
};

FormInputTransaksi.defaultProps = {
  status: "add", // or any default value you want
};

export default FormInputTransaksi;
