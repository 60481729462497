import { memo } from 'react';
import ApexChart from 'react-apexcharts';
import { alpha, styled } from '@mui/system';

const Chart = styled(ApexChart)(({ theme }) => ({
  '& .apexcharts-canvas': {
    // Tooltip
    '& .apexcharts-tooltip': {
      backgroundColor: alpha(theme.palette.background.default, 0.9),
      color: theme.palette.text.primary,
      boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1)`,
      borderRadius: `${theme.shape.borderRadius * 1.25}px`,
      '&.apexcharts-theme-light': {
        borderColor: 'transparent',
        backgroundColor: alpha(theme.palette.background.default, 0.9),
        boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1)`,
      },
    },
    '& .apexcharts-xaxistooltip': {
      backgroundColor: alpha(theme.palette.background.default, 0.9),
      borderColor: 'transparent',
      color: theme.palette.text.primary,
      boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1)`,
      borderRadius: `${theme.shape.borderRadius * 1.25}px`,
      '&:before': {
        borderBottomColor: alpha(theme.palette.grey[500], 0.24),
      },
      '&:after': {
        borderBottomColor: alpha(theme.palette.background.default, 0.8),
      },
    },
    '& .apexcharts-tooltip-title': {
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: alpha(theme.palette.grey[500], 0.08),
      color: theme.palette.text[theme.palette.mode === 'light' ? 'secondary' : 'primary'],
    },
    '& .apexcharts-legend': {
      padding: 0,
    },
    '& .apexcharts-legend-series': {
      display: 'inline-flex !important',
      alignItems: 'center',
    },
    '& .apexcharts-legend-marker': {
      marginRight: 8,
    },
    '& .apexcharts-legend-text': {
      lineHeight: '18px',
      textTransform: 'capitalize',
    },
  },
}));

export default memo(Chart);
