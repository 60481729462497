import { Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, TableContainer, List, ListItem, ListItemText, ListItemIcon, Dialog, DialogActions, DialogContent, IconButton, DialogTitle, DialogContentText } from "@mui/material";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CircleIcon from '@mui/icons-material/Circle';
import SaveIcon from '@mui/icons-material/Save';
import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import axios from "axios";
import { fCurrencyIDR } from "../features/numberFormat";
import TableAction from "./TableAction";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFRekapBulan from "./PDF/PDFRekapBulan";
import environment from "../enviroment";
import { useSelector, useDispatch } from 'react-redux';
import { getTransaksiById } from "../features/transaksiSlice";
import FormInputTransaksi from "./FormInputTransaksi";
import { getAkunSatu } from "../features/akunSlice";
import { updateTransaksi, reset } from "../features/transaksiSlice";
import AlertBar from "./AlertBar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),

  },
}));

const RekapLaporan = () => {

  const INPUT_FORM = {
    date: "",
    idAkunDua: "",
    jumlah: 0,
    keterangan:{
      ketInput:"", 
      wniDewasa:"", 
      wniAnakAnak:"", 
      wnaDewasa:"", 
      wnaAnakAnak:"",
      rodaEnam:"",
      rodaEmpat:"",
      rodaDua:"",
    },
    uploadFile: "",
  };

  const { akunSatu } = useSelector((state) => state.akun);
  const [rows, setRows] = useState([]);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [availableYears, setAvailableYears] = useState([]);
  const [defaultYear, setDefaultYear] = useState();
  const [defaultMonth, setDefaultMonth] = useState();
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalKredit, setTotalKredit] = useState(0);
  const [totalSaldo, setTotalSaldo] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  // const { data, isLoading, isError, message } = useSelector((state) => state.transaksi);




  useEffect(() => {

    const calculateTotals = (data) => {
      let totalDebit = 0;
      let totalKredit = 0;

      data.forEach((transaction) => {
        totalDebit += transaction.jumlahPendapatan || 0;
        totalKredit += transaction.jumlahPengeluaran || 0;
      });

      return { totalDebit, totalKredit };
    };

    const calculateTotalSaldo = (data) => {
      return data.length > 0 ? data[data.length - 1].saldo : 0;
    };

    const fetchData = async () => {
      try {
        const [transactionsResponse, akunDuaResponse, akunSatuResponse] = await Promise.all([
          axios.get("/transaksi"),
          axios.get("/akun-dua"),
          axios.get("/akun-satu"),
        ]);

        const transactionsData = transactionsResponse.data.data;
        const akunDuaData = akunDuaResponse.data;
        const akunSatuData = akunSatuResponse.data;

        transactionsData.sort((a, b) => new Date(a.date) - new Date(b.date));
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        // Set default year and month when the component is initially mounted
        if (!selectedYear) {
          setDefaultYear(currentYear);
          setSelectedYear(currentYear);
        }

        if (!selectedMonth) {
          setDefaultMonth(currentMonth);
          setSelectedMonth(currentMonth);
        }

        const uniqueYears = [...new Set(transactionsData.map(transaction => new Date(transaction.date).getFullYear()))];
        const sortedUniqueYears = uniqueYears.sort((a, b) => b - a); // Sorting secara descending
        setAvailableYears(sortedUniqueYears);

        let saldo = 0;

        const filteredData = transactionsData
          .filter((transaction) => {
            const transactionDate = new Date(transaction.date);
            return (
              (!selectedYear || transactionDate.getFullYear() === selectedYear) &&
              (!selectedMonth || transactionDate.getMonth() + 1 === selectedMonth)
            );
          })
          .map((transaction) => {
            const correspondingAkunDua = akunDuaData.find((akun) => akun.id === transaction.keuangan_akun_dua_id);
            const correspondingAkunSatuId = correspondingAkunDua ? correspondingAkunDua.keuangan_akun_satu_id : null;
            let transaksi = {};

            if (correspondingAkunSatuId === 1) {
              saldo += transaction.jumlah || 0;
              transaksi = {
                ...transaction,
                akunSatuData: akunSatuData.find((akun) => akun.id == correspondingAkunSatuId),
                nama_akun: correspondingAkunDua ? correspondingAkunDua.nama_akun : null,
                kode: correspondingAkunDua ? correspondingAkunDua.kode : null,
                jumlahPendapatan: transaction.jumlah || 0,
                jumlahPengeluaran: null,
                saldo: saldo,
              };
            } else if (correspondingAkunSatuId === 2) {
              saldo -= transaction.jumlah || 0;
              transaksi = {
                ...transaction,
                akunSatuData: akunSatuData.find((akun) => akun.id == correspondingAkunSatuId),
                nama_akun: correspondingAkunDua ? correspondingAkunDua.nama_akun : null,
                kode: correspondingAkunDua ? correspondingAkunDua.kode : null,
                jumlahPendapatan: null,
                jumlahPengeluaran: transaction.jumlah || 0,
                saldo: saldo,
              };
            }
            const pdfUrl = `${environment.baseUrl}/pdfs/${encodeURIComponent(transaction.uploadData)}`;

            return {
              ...transaksi,
              pdfUrl,
            };
          });

        const { totalDebit, totalKredit } = calculateTotals(filteredData);

        setRows(filteredData);
        const totalSaldo = calculateTotalSaldo(filteredData);
        setTotalDebit(totalDebit);
        setTotalKredit(totalKredit);
        setTotalSaldo(totalSaldo);

      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response.data);
        } else {
          console.error('Error:', error.message);
        }
      }
    };
    fetchData(selectedYear || defaultYear, selectedMonth || defaultMonth);
  }, [defaultYear, defaultMonth, selectedYear, selectedMonth, data]);

  const handleLihatClick = (pdfUrl) => {
    setSelectedPdfUrl(pdfUrl);
    setIsDialogOpen(true);
  };

  const handleClickOpen = async (data) => {
    setOpen(true);
    const keterangan = JSON.parse(data.keterangan)
    const formData = {
      ...data,
      // id: data.id,
      // nama_akun: data.nama_akun,
      date: (new Date(data.date)).toLocaleDateString('fr-CA'),
      idAkunDua: data.keuangan_akun_dua_id,
      // jumlah: data.jumlah,
      keterangan: keterangan,
    };
    setData(formData)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (e) => {
    try {
      e.preventDefault();

    if (Object.values(data.keterangan).some((val) => val !== "")) {
      const keteranganJson = JSON.stringify(data.keterangan);

      const combinedData = {
        ...data,
        keterangan: keteranganJson,
      };

      // Lakukan sesuatu dengan data yang telah dihasilkan
      // Lakukan submit dengan data yang sudah diubah
      dispatch(updateTransaksi(combinedData));

      // dispatch(createTransaksiAkun(dataInput));
      setData(INPUT_FORM);
      handleClose();
      setOpenAlert((prev) => !prev);
      setMessage(`${data.akunSatuData.nama_akun} Berhasil Disimpan`);
      setSeverity("success");
      dispatch(reset());
    } else {
      console.error("Tidak ada data yang diisi pada keterangan");
    }
    } catch (error) {
      setOpenAlert((prev) => !prev);
      setMessage(`Ada Kesalahan Saat Menyimpan ${data.akunSatuData.nama_akun}`);
      setSeverity("error");
      console.log(error);
    }
  }


  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    const locale = "id-ID";
    return new Date(dateTimeString).toLocaleDateString(locale, options);
  };

  const formatKeterangan = (keteranganString) => {
    try {
      const keteranganObj = JSON.parse(keteranganString);
  
      const formattedListItems = Object.entries(keteranganObj)
      .filter(([key, value]) => value !== "")
      .map(([key, value], index) => (
        <ListItem key={index} sx={{ ml:-2}}>
          <ListItemIcon sx={{ mr: -4}}>
            <CircleIcon sx={{ fontSize: 'small' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{`${getLabelByKeyName(key)}: ${value}`}</Typography>
          </ListItemText>
        </ListItem>
      ));

    return <List>{formattedListItems}</List>;
    } catch (error) {
      console.error('Error parsing keterangan:', error);
      return '-';
    }
  };

  const getLabelByKeyName = (key) => {
    switch (key) {
      case 'ketInput':
        return 'Keterangan ';
      case 'wniDewasa':
        return 'WNI Dewasa ';
      case 'wniAnakAnak':
        return 'WNI Anak-anak ';
      case 'wnaDewasa':
        return 'WNA Dewasa ';
      case 'wnaAnakAnak':
        return 'WNA Anak-anak ';
      case 'rodaEnam':
        return 'Micro Bus & Bus ';
      case 'rodaEmpat':
        return 'Mobil ';
      case 'rodaDua':
        return 'Motor ';
      default:
        return '';
    }
  };

  const handleYearClick = (year) => {
    setSelectedYear(year);
    setDefaultYear(year);
    setDefaultMonth(defaultMonth);
    setSelectedMonth(defaultMonth);

  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);

  };

  const monthNames = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const formattedLabel = selectedYear && selectedMonth
    ? `Laporan Bulan ${monthNames[selectedMonth - 1]} ${selectedYear}`
    : 'Rekap Bulanan';


  return (
    <>

<AlertBar
        open={openAlert}
        setOpen={setOpenAlert}
        message={message}
        severity={severity}
      />
      
      <Box px={3} my={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Typography>Pilih Tahun</Typography>
            <Grid container spacing={1}>
              {availableYears.map((year) => (
                <Grid item key={year} xs={6} sm={4} md={3} lg={2}>
                  <Button
                    variant={selectedYear === year ? "contained" : "outlined"}
                    sx={{
                      color: selectedYear === year ? "#FFFFFF" : "#7B57E0",
                      backgroundColor: selectedYear === year ? "#7B57E0" : "transparent",
                      borderColor: "#7B57E0",
                      ':hover': {
                        backgroundColor: "#7B57E0",
                        borderColor: "#7B57E0",
                        color: "#FFFFFF"

                      },
                    }}
                    onClick={() => handleYearClick(year)}
                    fullWidth
                  >
                    {year}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography>Pilih Bulan</Typography>
            <Grid container spacing={1}>
              {monthNames.map((month, index) => (
                <Grid item key={month} xs={6} sm={4} md={3} lg={2}>
                  <Button
                    variant={selectedMonth === index + 1 ? "contained" : "outlined"}
                    sx={{
                      color: selectedMonth === index + 1 ? "#FFFFFF" : "#7B57E0",
                      backgroundColor: selectedMonth === index + 1 ? "#7B57E0" : "transparent",
                      borderColor: "#7B57E0",
                      textTransform: 'none',
                      ':hover': {
                        backgroundColor: "#6F4DBF",
                        borderColor: "#6F4DBF",
                        color: "#FFFFFF"
                      },
                    }}
                    onClick={() => handleMonthClick(index + 1)}
                    fullWidth
                  >
                    {month}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 4, mb: 1 }}>
          <Grid item xs={12} md={8}>
            <Typography variant='h6' id="pdf-typography">{formattedLabel}</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <PDFDownloadLink document={<PDFRekapBulan rows={rows} formatDateTime={formatDateTime} totalDebit={totalDebit} totalKredit={totalKredit} totalSaldo={totalSaldo} formattedLabel={formattedLabel} />} filename="FORM">
              {({ loading }) => (loading ? <button>Loading Document...</button> : <Button
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: '14px',
                  minWidth: 'auto'
                }}
              >
                Unduh</Button>)}
            </PDFDownloadLink>
          </Grid>
        </Grid>


        {/* <Box>
          <PDFViewer width={'100%'} height={500}>
            <PDFRekapBulan rows={rows} formatDateTime={formatDateTime} totalDebit={totalDebit}
              totalKredit={totalKredit}
              totalSaldo={totalSaldo} formattedLabel={formattedLabel} />
          </PDFViewer>
        </Box> */}

      </Box>

      <Grid xs={12}>
        <TableContainer id="pdf-table-container">
          <Table aria-label="simple table" sx={{ width: "100%" }}>
            <TableHead sx={{ background: "#F1F3F4" }}>
              <TableRow>

                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={100}
                  align="left"
                >
                  Tanggal
                </TableCell>
                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={100}
                  align="center"
                >
                  Akun
                </TableCell>

                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={200}
                  align="left"
                >
                  Keterangan
                </TableCell>
                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={150}
                  align="left"
                >
                  Debit
                </TableCell>
                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={150}
                  align="left"
                >
                  Kredit
                </TableCell>
                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={150}
                  align="left"
                >
                  Saldo
                </TableCell>
                <TableCell
                  sx={{ color: "#7D7D7D", fontWeight: 600 }}
                  width={150}
                  align="left"
                  className="exclude-from-pdf"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="left">{formatDateTime(row.date)}</TableCell>
                  <TableCell align="center">{row.kode}</TableCell>
                  <TableCell align="left">
                    {row.keterangan && typeof row.keterangan === 'string'
                    ? formatKeterangan(row.keterangan)
                    : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {row.jumlahPendapatan !== null ? fCurrencyIDR(row.jumlahPendapatan) : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {row.jumlahPengeluaran !== null ? fCurrencyIDR(row.jumlahPengeluaran) : "-"}
                  </TableCell>
                  <TableCell align="left">{fCurrencyIDR(row.saldo || '0')}</TableCell>
                  <TableCell className="exclude-from-pdf">
                    <Button
                      variant="contained"
                      startIcon={<FindInPageIcon />}
                      sx={{
                        background: "#7B57E0",
                        textTransform: 'none',
                        fontSize: '14px',
                        minWidth: '6rem',
                        '&:hover': {
                          background: "#6F4DBF",
                        },
                        m: 1
                      }}
                      onClick={() => handleLihatClick(row.pdfUrl)}
                    >
                      Bukti
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<EditIcon/>}
                        sx={{
                          background: "#7B57E0",
                          textTransform: 'none',
                          fontSize: '14px',
                          minWidth: '6rem',
                          '&:hover': {
                            background: "#6F4DBF",
                          },
                          m: 1
                        }}
                        onClick={() => handleClickOpen(row)}
                      >
                        Edit
                      </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="left" colSpan={2}></TableCell> {/* Tambahkan cell kosong */}
                <TableCell align="left" colSpan={1}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Jumlah</Typography>
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold' }}>{fCurrencyIDR(totalDebit || '0')}</TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold' }}>{fCurrencyIDR(totalKredit || '0')}</TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold' }}>{fCurrencyIDR(totalSaldo || '0')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <FormInputTransaksi status={'edit'} akunSatuData={data.akunSatuData} dataInput={data} setDataInput={setData} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleUpdate}>
            Simpan Perubahan
          </Button>
        </DialogActions>
        </BootstrapDialog>

      <TableAction
        pdfUrl={selectedPdfUrl}
        isOpen={isDialogOpen}
        onClose={() => {
          setSelectedPdfUrl(null);
          setIsDialogOpen(false);
        }}
      />
    </>
  )

}

export default RekapLaporan;
