import { Grid, Typography } from "@mui/material"
import React from "react"

const GridTotal = ({ name, value }) => {
    return (

        <Grid container direction="row" justifyContent="space-between" mt={2}>
            <Grid item md={6}>
                <Typography variant="h6">{name}</Typography>
            </Grid>
            <Grid item md={6}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item md={6} display="flex" justifyContent="flex-end">
                        <Typography>Rp</Typography>
                    </Grid>
                    <Grid item md={6} display="flex" justifyContent="flex-end">
                        <Typography>{value}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default GridTotal;