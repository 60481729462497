// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  InputLabel,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import {
  createEvent,
  setTotal,
  setDiscount,
  calculateDiscountedPrice,
  sumPengeluaran,
  reset,
} from "../features/eventSlice";
import { useDispatch } from "react-redux";
import CardComponent from "./CardComponent";
import InputCurrency from "./FormInput/InputCurrency";
import InputFile from "./FormInput/InputFile";
import { fCurrencyIDR } from "../features/numberFormat";
import { ReactComponent as SaveIcon } from "../assets/icons/save.svg";
import InputSelect from "./FormInput/InputSelect";
import ModalNotifikasi from "./ModalNotifikasi";
import AlertBar from "./AlertBar";
import { ReactComponent as AskIcon } from "../assets/icons/ask_warning.svg";

const FormInputEvent = ({ dataEvent }) => {
  const INPUT_FORM = {
    namaPj: "",
    namaTamu: "",
    paket: "",
    date: "",
    harga: 0,
    disc: 0,
    uploadPendapatan: "",
    akomodasi: 0,
    uploadAkomodasi: '',
    pemandu: 0,
    uploadPemandu: '',
    sewa: 0,
    uploadSewa: '',
    kegiatan: 0,
    uploadKegiatan: '',
    keamanan: 0,
    uplaodKeamanan: '',
    konsumsi: 0,
    uploadKonsumsi: '',
    lainLain: 0,
    uploadLainlain: "",
  };

  const EMPTY_FORM_VALIDATION = {
    namaPj: { error: false, helperText: "" },
    namaTamu: { error: false, helperText: "" },
    paket: { error: false, helperText: "" },
    date: { error: false, helperText: "" },
    harga: { error: false, helperText: "" },
    disc: { error: false, helperText: "" },
    uploadPendapatan: { error: false, helperText: "" },
    akomodasi: { error: false, helperText: "" },
    uploadAkomodasi: { error: false, helperText: "" },
    pemandu: { error: false, helperText: "" },
    uploadPemandu: { error: false, helperText: "" },
    sewa: { error: false, helperText: "" },
    uploadSewa: { error: false, helperText: "" },
    kegiatan: { error: false, helperText: "" },
    uploadKegiatan: { error: false, helperText: "" },
    keamanan: { error: false, helperText: "" },
    uplaodKeamanan: { error: false, helperText: "" },
    konsumsi: { error: false, helperText: "" },
    uploadKonsumsi: { error: false, helperText: "" },
    lainLain: { error: false, helperText: "" },
    uploadLainlain: { error: false, helperText: "" },
  };

  const dispatch = useDispatch();
  const [dataInput, setDataInput] = useState(INPUT_FORM);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [formValidation, setFormValidation] = useState(EMPTY_FORM_VALIDATION);
  const total = useSelector((state) => state.event.price.total);
  const totalPengeluaran = useSelector((state) => state.event.pengeluaran.total);

  useEffect(() => {
    dispatch(setTotal(dataInput.harga));
    dispatch(setDiscount(dataInput.disc));
    dispatch(calculateDiscountedPrice());
  }, [dispatch, dataInput.harga, dataInput.disc])

  useEffect(() => {
    dispatch(sumPengeluaran([dataInput.akomodasi, dataInput.pemandu, dataInput.sewa, dataInput.kegiatan, dataInput.keamanan, dataInput.konsumsi, dataInput.lainLain]))
  }, [dispatch, dataInput])


  const handleClose = () => {
    setOpen(false);
  };

  const validateForm = () => {
    var formValidation = EMPTY_FORM_VALIDATION;
    var isError = false;

    if (dataInput.namaPj == "") {
      formValidation.namaPj.error = true;
      formValidation.namaPj.helperText = "Mohon masukkan Nama Penanggung Jawab";
      isError = true;
    }
    if (dataInput.namaTamu == "") {
      formValidation.namaTamu.error = true;
      formValidation.namaTamu.helperText = "Mohon masukkan Nama Tamu";
      isError = true;
    }
    if (dataInput.paket == "") {
      formValidation.paket.error = true;
      formValidation.paket.helperText = "Mohon Pilih Paket";
      isError = true;
    }

    if (dataInput.date == "") {
      formValidation.date.error = true;
      formValidation.date.helperText = "Mohon masukkan Tanggal";
      isError = true;
    }

    if (dataInput.harga == "") {
      formValidation.harga.error = true;
      formValidation.harga.helperText = "Mohon Masukan Harga";
      isError = true;
    }

    if (dataInput.uploadPendapatan == "") {
      formValidation.uploadPendapatan.error = true;
      formValidation.uploadPendapatan.helperText = `Mohon Upload Bukti Pendapatan`;
      isError = true;
    }
    if (dataInput.akomodasi !== 0) {
      if (dataInput.uploadAkomodasi == "") {
        formValidation.uploadAkomodasi.error = true;
        formValidation.uploadAkomodasi.helperText = `Mohon Upload Bukti Akomodasi`;
        isError = true;
      }
    }
    if (dataInput.pemandu !== 0) {
      if (dataInput.uploadPemandu == "") {
        formValidation.uploadPemandu.error = true;
        formValidation.uploadPemandu.helperText = `Mohon Upload Bukti Pemandu`;
        isError = true;
      }
    }
    if (dataInput.sewa !== 0) {
      if (dataInput.uploadSewa == "") {
        formValidation.uploadSewa.error = true;
        formValidation.uploadSewa.helperText = `Mohon Upload Bukti Akomodasi`;
        isError = true;
      }
    }
    if (dataInput.kegiatan !== 0) {
      if (dataInput.uploadKegiatan == "") {
        formValidation.uploadKegiatan.error = true;
        formValidation.uploadKegiatan.helperText = `Mohon Upload Bukti Kegiatan`;
        isError = true;
      }
    }
    if (dataInput.keamanan !== 0) {
      if (dataInput.UplaodKeamanan == "") {
        formValidation.UplaodKeamanan.error = true;
        formValidation.UplaodKeamanan.helperText = `Mohon Upload Bukti Keamanan`;
        isError = true;
      }
    }
    if (dataInput.konsumsi !== 0) {
      if (dataInput.uploadKonsumsi == "") {
        formValidation.uploadKonsumsi.error = true;
        formValidation.uploadKonsumsi.helperText = `Mohon Upload Bukti Konsumsi`;
        isError = true;
      }
    }
    if (dataInput.lainLain !== 0) {
      if (dataInput.uploadLainlain == "") {
        formValidation.uploadLainlain.error = true;
        formValidation.uploadLainlain.helperText = `Mohon Upload Bukti Lain-lain`;
        isError = true;
      }
    }


    setFormValidation(formValidation);
    return !isError;
  };

  const handleOpen = () => {
    const isValid = validateForm();
    if (isValid) {
      setOpen(true);
    } else {
      setOpenAlert((prev) => !prev);
      setMessage(`Mohon Periksa Kembali Data Anda`);
      setSeverity("error");
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      dispatch(createEvent(dataInput));
      setDataInput(INPUT_FORM);
      dispatch(reset());
      handleClose();
      setOpenAlert((prev) => !prev);
      setMessage(`Event Berhasil Disimpan`);
      setSeverity("success");
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      setOpenAlert((prev) => !prev);
      setMessage(`Ada Kesalahan Saat Menyimpan Event`);
      setSeverity("error");
      console.log(error);
    }
  };

  return (
    <>
      <AlertBar
        open={openAlert}
        setOpen={setOpenAlert}
        message={message}
        severity={severity}
      />

      <ModalNotifikasi
        open={open}
        handleClose={handleClose}
        buttonAction={handleSubmit}
        status={"add"}
        title={'Konfirmasi Menyimpan Data'}
        icon={<AskIcon />}
        contentText={"Apakah data yang anda masukkan sudah benar?"}
      />

      <CardComponent
        title={"Kontrak Event"}
        subTitle={`Masukan Data Event Kontrak`}
        content={
          <>
            {/* MASUKAN DATA EVENT KONTRAK */}
            <Box px={3}>
              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    required
                    htmlFor="nama_pj"
                    error={formValidation.namaPj.error}
                  >
                    Penanggung Jawab
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    error={formValidation.namaPj.error}
                    helperText={formValidation.namaPj.helperText}
                    size="small"
                    fullWidth
                    placeholder="Masukan Nama Penanggung Jawab"
                    id="nama_pj"
                    type="text"
                    value={dataInput.namaPj}
                    onChange={(e) => {
                      setDataInput({ ...dataInput, namaPj: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    required
                    htmlFor="nama_tamu"
                    error={formValidation.namaTamu.error}
                  >
                    Nama Tamu
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    error={formValidation.namaTamu.error}
                    helperText={formValidation.namaTamu.helperText}
                    size="small"
                    fullWidth
                    placeholder="Masukan Nama Tamu"
                    id="nama_tamu"
                    type="text"
                    value={dataInput.namaTamu}
                    onChange={(e) => {
                      setDataInput({ ...dataInput, namaTamu: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 2, md: 0.1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    required
                    htmlFor="event"
                    error={formValidation.paket.error}
                  >
                    Paket
                  </InputLabel>
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputSelect
                    id="event"
                    error={formValidation.paket.error}
                    helperText={formValidation.paket.helperText}
                    value={dataInput.paket}
                    options={dataEvent}
                    onChange={(value) =>
                      setDataInput({ ...dataInput, paket: value })
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    required
                    htmlFor="date"
                    error={formValidation.date.error}
                  >
                    Tanggal
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    error={formValidation.date.error}
                    helperText={formValidation.date.helperText}
                    size="small"
                    fullWidth
                    placeholder="Masukan Tanggal"
                    id="date"
                    type="date"
                    value={dataInput.date}
                    onChange={(e) => {
                      setDataInput({ ...dataInput, date: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* MASUKAN DATA PENDAPATAN */}
            <Box bgcolor="#7B57E0" mt={2}>
              <Typography
                m={0}
                px={3}
                py={2}
                sx={{ textTransform: "uppercase", color: "white" }}
                variant="subtitle1"
              >
                {"MASUKAN DATA PENDAPATAN"}
              </Typography>
            </Box>
            <Box px={3}>
              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    required
                    htmlFor="harga"
                    error={formValidation.harga.error}
                  >
                    Harga
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <InputCurrency
                    error={formValidation.harga.error}
                    helperText={formValidation.harga.helperText}
                    value={dataInput.harga}
                    onChange={(value) =>
                      setDataInput({ ...dataInput, harga: value })
                    }
                    placeholder={"Masukan Harga"}
                    id={"harga"}
                  />
                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel

                    htmlFor="disc"
                  >
                    Diskon
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={4}>

                  <TextField

                    size="small"
                    fullWidth
                    placeholder="Masukan Jumlah Diskon"
                    id="disc"
                    type="number"
                    value={dataInput.disc == 0 ? "" : dataInput.disc}
                    onChange={(e) => {
                      let value = e.target.value
                      if (value >= 0 && value <= 100 && value.length <= 3) {
                        setDataInput({ ...dataInput, disc: value });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: { min: 0, max: 100 }
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    required
                    htmlFor="upload_pendapatan"
                    error={formValidation.uploadPendapatan.error}
                  >
                    {`Upload Bukti Pendapatan`}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputFile
                    id="upload_pendapatan"
                    error={formValidation.uploadPendapatan.error}
                    helperText={formValidation.uploadPendapatan.helperText}
                    fileName={dataInput.uploadPendapatan?.name}
                    onChange={(value) =>
                      setDataInput({ ...dataInput, uploadPendapatan: value })
                    }
                    placeholder={'Upload Bukti Pendapatan'}
                  />
                </Grid>
              </Grid>

              <Box>
                <Grid container direction="column">
                  <Grid item display="flex" justifyContent="flex-end">
                    <Typography variant="subtitle1">Total Pemasukan</Typography>
                  </Grid>
                  <Grid item display="flex" justifyContent="flex-end">
                    <Typography variant="h5">{fCurrencyIDR(total.toString().replace('.', ','))}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* MASUKAN DATA PENGELUARAN */}
            <Box bgcolor="#7B57E0" mt={2}>
              <Typography
                m={0}
                px={3}
                py={2}
                sx={{ textTransform: "uppercase", color: "white" }}
                variant="subtitle1"
              >
                {"MASUKAN DATA PENGELUARAN"}
              </Typography>
            </Box>
            <Box px={3}>
              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    // required
                    htmlFor="akomodasi"
                  // error={formValidation.harga.error}
                  >
                    Akomodasi
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={{ xs: 0, md: 2 }}
                    direction={{ sm: "column", md: "row" }}>
                    <Grid item xs={12} md={5}>
                      <InputCurrency
                        // error={formValidation.harga.error}
                        // helperText={formValidation.harga.helperText}
                        value={dataInput.akomodasi}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, akomodasi: value })
                        }
                        placeholder={"Masukan Biaya Akomodasi"}
                        id={"akomodasi"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputFile
                        id="upload_akomodasi"
                        error={formValidation.uploadAkomodasi.error}
                        helperText={formValidation.uploadAkomodasi.helperText}
                        fileName={dataInput.uploadAkomodasi?.name}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, uploadAkomodasi: value })
                        }
                        placeholder={'Upload Bukti Akomodasi'}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    // required
                    htmlFor="pemandu"
                  // error={formValidation.harga.error}
                  >
                    Pemandu
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={{ xs: 0, md: 3 }}
                    direction={{ sm: "column", md: "row" }}>
                    <Grid item xs={12} md={5}>
                      <InputCurrency
                        value={dataInput.pemandu}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, pemandu: value })
                        }
                        placeholder={"Masukan Biaya Pemandu"}
                        id={"pemandu"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputFile
                        id="upload_pemandu"
                        error={formValidation.uploadPemandu.error}
                        helperText={formValidation.uploadPemandu.helperText}
                        fileName={dataInput.uploadPemandu?.name}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, uploadPemandu: value })
                        }
                        placeholder={'Upload Bukti Pemandu'}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    // required
                    htmlFor="sewa"
                  // error={formValidation.harga.error}
                  >
                    Sewa
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={{ xs: 0, md: 3 }}
                    direction={{ sm: "column", md: "row" }}>
                    <Grid item xs={12} md={5}>
                      <InputCurrency
                        // error={formValidation.harga.error}
                        // helperText={formValidation.harga.helperText}
                        value={dataInput.sewa}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, sewa: value })
                        }
                        placeholder={"Masukan Biaya Sewa"}
                        id={"sewa"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputFile
                        id="upload_sewa"
                        error={formValidation.uploadSewa.error}
                        helperText={formValidation.uploadSewa.helperText}
                        fileName={dataInput.uploadSewa?.name}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, uploadSewa: value })
                        }
                        placeholder={'Upload Bukti Sewa'}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    // required
                    htmlFor="kegiatan"
                  // error={formValidation.harga.error}
                  >
                    Kegiatan
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={{ xs: 0, md: 3 }}
                    direction={{ sm: "column", md: "row" }}>
                    <Grid item xs={12} md={5}>
                      <InputCurrency
                        // error={formValidation.harga.error}
                        // helperText={formValidation.harga.helperText}
                        value={dataInput.kegiatan}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, kegiatan: value })
                        }
                        placeholder={"Masukan Biaya Kegiatan"}
                        id={"kegiatan"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputFile
                        id="upload_kegiatan"
                        error={formValidation.uploadKegiatan.error}
                        helperText={formValidation.uploadKegiatan.helperText}
                        fileName={dataInput.uploadKegiatan?.name}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, uploadKegiatan: value })
                        }
                        placeholder={'Upload Bukti Kegiatan'}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    // required
                    htmlFor="keamanan"
                  // error={formValidation.harga.error}
                  >
                    Keamanan
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={{ xs: 0, md: 3 }}
                    direction={{ sm: "column", md: "row" }}>
                    <Grid item xs={12} md={5}>
                      <InputCurrency
                        // error={formValidation.harga.error}
                        // helperText={formValidation.harga.helperText}
                        value={dataInput.keamanan}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, keamanan: value })
                        }
                        placeholder={"Masukan Biaya Keamanan"}
                        id={"keamanan"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputFile
                        id="upload_keamanan"
                        error={formValidation.uplaodKeamanan.error}
                        helperText={formValidation.uplaodKeamanan.helperText}
                        fileName={dataInput.uplaodKeamanan?.name}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, uplaodKeamanan: value })
                        }
                        placeholder={'Upload Bukti Keamanan'}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    // required
                    htmlFor="konsumsi"
                  // error={formValidation.harga.error}
                  >
                    Konsumsi
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>

                  <Grid container spacing={{ xs: 0, md: 3 }}
                    direction={{ sm: "column", md: "row" }}>
                    <Grid item xs={12} md={5}>
                      <InputCurrency
                        // error={formValidation.harga.error}
                        // helperText={formValidation.harga.helperText}
                        value={dataInput.konsumsi}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, konsumsi: value })
                        }
                        placeholder={"Masukan Biaya Konsumsi"}
                        id={"konsumsi"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputFile
                        id="upload_konsumsi"
                        error={formValidation.uploadKonsumsi.error}
                        helperText={formValidation.uploadKonsumsi.helperText}
                        fileName={dataInput.uploadKonsumsi?.name}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, uploadKonsumsi: value })
                        }
                        placeholder={'Upload Bukti Konsumsi'}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>



              <Grid
                mt={{ xs: 1 }}
                container
                spacing={{ xs: 0, md: 3 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel
                    // required
                    htmlFor="lain_lain"
                  // error={formValidation.harga.error}
                  >
                    Lain-lain
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={{ xs: 0, md: 3 }}
                    direction={{ sm: "column", md: "row" }}>
                    <Grid item xs={12} md={5}>
                      <InputCurrency
                        // error={formValidation.harga.error}
                        // helperText={formValidation.harga.helperText}
                        value={dataInput.lainLain}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, lainLain: value })
                        }
                        placeholder={"Masukan Biaya Lain-lain"}
                        id={"lain_lain"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputFile
                        id="upload_lainlain"
                        error={formValidation.uploadLainlain.error}
                        helperText={formValidation.uploadLainlain.helperText}
                        fileName={dataInput.uploadLainlain?.name}
                        onChange={(value) =>
                          setDataInput({ ...dataInput, uploadLainlain: value })
                        }
                        placeholder={'Upload Bukti Lain-lain'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Box marginY={3}>
                <Grid container direction="column">
                  <Grid item display="flex" justifyContent="flex-end">
                    <Typography variant="subtitle1">Total Pengeluaran</Typography>
                  </Grid>
                  <Grid item display="flex" justifyContent="flex-end">
                    <Typography variant="h5">{fCurrencyIDR(totalPengeluaran.toString().replace('.', ','))}</Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid container direction="column">
                  <Grid item display="flex" justifyContent="flex-end">
                    <Typography variant="h6">Jumlah</Typography>
                  </Grid>
                  <Grid item display="flex" justifyContent="flex-end">
                    <Typography variant="h5">{fCurrencyIDR((total - totalPengeluaran).toString().replace('.', ','))}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>




          </>
        }
        action={
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => handleOpen()}
            // onSubmit={() => handleSubmit()}
            >
              Simpan
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default FormInputEvent;
