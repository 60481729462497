import { Dialog, DialogContent, Typography, Button } from '@mui/material';
import { Document } from 'react-pdf';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const TableAction = ({ pdfUrl, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Typography variant="h5" mb={2}>
          Lihat PDF
        </Typography>
        {/* Gunakan react-pdf untuk menampilkan PDF */}
        <Document file={pdfUrl}>
            <iframe src={pdfUrl} title="PDF Viewer" width="100%" height="600px" />
        </Document>
        <Button variant="contained" onClick={onClose} sx={{ marginTop: 2 }}>
          Tutup
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TableAction;
