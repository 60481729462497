import Layout from "./Layout";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RekapTahunan from "../components/RekapTahunan";
import CardComponent from "../components/CardComponent";

const RekapLaporanTahunan = () => {
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);

  return (
    <Layout>
      <CardComponent
        title={'Rekap Tahunan'}
        subTitle={'Pilih Laporan Berdasarkan Tahun'}
        content={
          <RekapTahunan />
        }
      />
    </Layout>
  );
};

export default RekapLaporanTahunan;