import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  transaksi: {
    data: [],
    // id: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

export const createTransaksiAkun = createAsyncThunk(
  "transaksi/createTransaksi",
  async (data, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("uploadData", data.uploadFile);
      formData.append("date", data.date);
      formData.append("keuangan_akun_dua_id", data.idAkunDua);
      formData.append("jumlah", data.jumlah);
      formData.append("keterangan", data.keterangan);
      const response = await axios.post(`/transaksi`, formData);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const getAllTransaksi = createAsyncThunk(
  "transaksi/getAllKeuanganTransaksi",
  () => {
    return axios.get("/transaksi").then((response) => response.data);
  }
);

export const getTransaksiById = createAsyncThunk(
  "transaksi/getTransaksiById",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/transaksi/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const updateTransaksi = createAsyncThunk(
  "transaksi/updateTransaksi",
  async (data, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("upload_data", data.uploadFile);
      formData.append("date", data.date);
      formData.append("keuangan_akun_dua_id", data.idAkunDua);
      formData.append("jumlah", data.jumlah);
      formData.append("keterangan", data.keterangan);
      const response = await axios.patch(`/transaksi/${data.id}`, formData);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);


export const transaksiSlice = createSlice({
  name: "transaksi",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTransaksi.pending, (state) => {
      state.transaksi.isLoading = true;
    });
    builder.addCase(getAllTransaksi.fulfilled, (state, action) => {
      state.transaksi.isLoading = false;
      state.transaksi.isSuccess = true;
      state.transaksi.data = action.payload;
    });
    builder.addCase(getAllTransaksi.rejected, (state, action) => {
      state.transaksi.isLoading = false;
      state.transaksi.isError = true;
      // state.akunSatu.data = [];
      state.transaksi.message = action.payload;
    });

    builder.addCase(createTransaksiAkun.pending, (state) => {
      state.transaksi.isLoading = true;
    });
    builder.addCase(createTransaksiAkun.fulfilled, (state, action) => {
      state.transaksi.isLoading = false;
      state.transaksi.isSuccess = true;
      if (!Array.isArray(state.transaksi.data)) {
        state.transaksi.data = [];
      }
      state.transaksi.data.push(action.payload);
    });
    builder.addCase(createTransaksiAkun.rejected, (state, action) => {
      state.transaksi.isLoading = false;
      state.transaksi.isError = true;
      state.transaksi.message = action.payload;
    });

    builder.addCase(getTransaksiById.pending, (state) => {
      state.transaksi.isLoading = true;
    });
    builder.addCase(getTransaksiById.fulfilled, (state, action) => {
      state.transaksi.isLoading = false;
      state.transaksi.isSuccess = true;
      state.transaksi.data = action.payload;
    });
    builder.addCase(getTransaksiById.rejected, (state, action) => {
      state.transaksi.isLoading = false;
      state.transaksi.isError = true;
      state.transaksi.message = action.payload;
    });

    //update
        builder.addCase(updateTransaksi.pending, (state) => {
          state.transaksi.isLoading = true;
        });
        builder.addCase(updateTransaksi.fulfilled, (state, action) => {
          state.transaksi.isLoading = false;
          state.transaksi.isSuccess = true;
          const updateItem = action.payload;
          const index = state.transaksi.data.findIndex(
            (item) => item.id === updateItem.id
          );
          if (index !== -1) {
            state.transaksi.data[index] = updateItem;
          }
        });
        builder.addCase(updateTransaksi.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        });
  },
});

export const { reset } = transaksiSlice.actions;
export default transaksiSlice.reducer;
