import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import akunReducer from "../features/akunSlice";
import paketSlice from "../features/paketSlice";
import transaksiSlice from "../features/transaksiSlice";
import eventSlice from "../features/eventSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    akun: akunReducer,
    paket: paketSlice,
    transaksi: transaksiSlice,
    event: eventSlice,
  },
});
