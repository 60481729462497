const environment = {
  baseUrl: `${process.env.REACT_APP_URL}`,
  env: process.env.REACT_APP_ENV,
};

// if (process.env.REACT_APP_ENV === "development") {
//   environment.baseUrl = "http://example.com";
// }

// if (process.env.REACT_APP_ENV === "production") {
//   environment.baseUrl = "https://silakan.roamin.id/";
// }

export default environment;
