import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/authSlice";
import { Box, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Navbar = ({ open, handleDrawerOpen, AppBar }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getMe())
  }, [dispatch])



  return (

    <>
      <AppBar position="absolute" open={open} sx={{ backgroundColor: '#FFFFFF' }} elevation={0}>
        <Toolbar alignItems="center">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}

            sx={{
              mr: 2, ...(open && { display: 'none' }), color: 'black'
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography color='black' variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
            SISTEM INFORMASI PEMBUKUAN DESA WISATA JATILUWIH
          </Typography>
          <Box component='div'>
            <Grid container spacing={1}>
              <Grid item>
                <AccountCircleIcon fontSize="large" color="primary" />
              </Grid>
              <Grid item>
                <Box display={"flex"} flexDirection={'column'}>
                  <Typography color="black" variant="body2">{user && user.name}</Typography>
                  <Typography color="black" variant="caption">{user && user.email}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

        </Toolbar>
      </AppBar>
    </>

  );
};

export default Navbar;
