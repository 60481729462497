import React from "react";
import {
  Box,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";

export const Footer = () => {
  const itemLogos = [
    {
      img: "/logos/logo_ipbi.png",
      title: "IPB Internasional",
    },
    {
      img: "/logos/logo_kotak_roamin.png",
      title: "Roamin",
    },
    {
      img: "/logos/logo_balichili.png",
      title: "The Balichili",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        display: "flex",
        justifyContent: "center",
        // position: "relative",
        bottom: 0,
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction={"row"} justifyContent="space-between">
          <Grid item xs={6}>
            <Grid
              container
              direction={"row"}
              alignItems={"flex-start"}
              spacing={1}
            >
              <Grid item>
                <Typography color="black" variant="caption">
                  Suppor By:
                </Typography>
              </Grid>
              <Grid item>
                <ImageList cols={3} gap={8}>
                  {itemLogos.map((item, index) => (
                    <ImageListItem key={index}>
                      <Box
                        component="img"
                        sx={{
                          width: "70px",
                          height: "auto",
                        }}
                        alt={item.title}
                        src={item.img}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography color="black" variant="subtitle1">
              {`© ${new Date().getFullYear()} PT. Royal Mas Tripnusa All rights reserved. Made in Indonesia.`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
